import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const categoriesApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Categories', 'Subcategories'],
  endpoints: (builder) => ({
    categoryList: builder.query<Pto.Categories.List, Pto.Categories.CategoriesListQuery>({
      query: (params) => ({
        url: `/categories`,
        params
      }),
      providesTags: (categoryListData, _error, _args) =>
        getProvidedTags(
          'Categories',
          'LIST',
          categoryListData?.items.map((item) => item.id)
        )
    }),
    category: builder.query<Pto.Categories.Category, string>({
      query: (categoryId) => ({
        url: `/categories/${categoryId}`
      }),
      providesTags: (_result, _error, categoryId) => getProvidedTags('Categories', categoryId)
    }),
    createCategory: builder.mutation<string, Pto.Categories.CreateCategory>({
      query: (createCategoryDto) => ({
        url: 'categories',
        body: createCategoryDto,
        method: 'POST'
      }),
      invalidatesTags: ['Categories']
    }),
    updateCategoryName: builder.mutation<boolean, Pto.Categories.ChangeName & { categoryId: string }>({
      query: ({ categoryId, ...args }) => ({
        url: `categories/${categoryId}/name`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { categoryId }) => [{ type: 'Categories', id: categoryId }]
    }),
    updateCategoryDisplayName: builder.mutation<boolean, Pto.Categories.ChangeDisplayName & { categoryId: string }>({
      query: ({ categoryId, ...args }) => ({
        url: `categories/${categoryId}/display-name`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { categoryId }) => [{ type: 'Categories', id: categoryId }]
    }),
    updateCategoryImageUrl: builder.mutation<boolean, Pto.Categories.ChangeImageUrl & { categoryId: string }>({
      query: ({ categoryId, ...args }) => ({
        url: `categories/${categoryId}/image-url`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { categoryId }) => [{ type: 'Categories', id: categoryId }]
    }),
    updateCategoryIsActive: builder.mutation<boolean, Pto.Categories.ChangeIsActive & { categoryId: string }>({
      query: ({ categoryId, ...args }) => ({
        url: `categories/${categoryId}/is-active`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { categoryId }) => [{ type: 'Categories', id: categoryId }]
    }),
    updateCategorySeoUrl: builder.mutation<boolean, Pto.Categories.ChangeSeoUrl & { categoryId: string }>({
      query: ({ categoryId, ...args }) => ({
        url: `categories/${categoryId}/seo-url`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { categoryId }) => [{ type: 'Categories', id: categoryId }]
    }),
    addCategoryAttribute: builder.mutation<boolean, Pto.Categories.AddAttribute & { categoryId: string }>({
      query: ({ categoryId, ...args }) => ({
        url: `categories/${categoryId}/attribute`,
        body: args,
        method: 'PUT'
      }),
      invalidatesTags: (result, error, { categoryId }) => [{ type: 'Categories', id: categoryId }]
    }),
    removeCategoryAttribute: builder.mutation<void, { categoryId: string; attributeId: string }>({
      query: ({ categoryId, attributeId }) => ({
        url: `categories/${categoryId}/attribute/${attributeId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { categoryId }) => [{ type: 'Categories', id: categoryId }]
    }),

    subcategory: builder.query<Pto.Categories.Subcategory, { categoryId: string; subcategoryId: string }>({
      query: (args) => ({
        url: `/categories/${args.categoryId}/${args.subcategoryId}`
      }),
      providesTags: (_result, _error, args) => getProvidedTags('Subcategories', args.subcategoryId)
    }),
    createSubcategory: builder.mutation<string, Pto.Categories.CreateSubcategory & { categoryId: string }>({
      query: ({ categoryId, ...args }) => ({
        url: `categories/${categoryId}/create`,
        body: args,
        method: 'POST'
      }),
      invalidatesTags: (result, error, { categoryId }) => [{ type: 'Categories', id: categoryId }]
    }),
    updateSubcategoryName: builder.mutation<boolean, Pto.Categories.ChangeName & { categoryId: string; subcategoryId: string }>({
      query: ({ categoryId, subcategoryId, ...args }) => ({
        url: `categories/${categoryId}/${subcategoryId}/name`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, args) => getProvidedTags('Subcategories', args.subcategoryId)
    }),
    updateSubcategoryDisplayName: builder.mutation<boolean, Pto.Categories.ChangeDisplayName & { categoryId: string; subcategoryId: string }>({
      query: ({ categoryId, subcategoryId, ...args }) => ({
        url: `categories/${categoryId}/${subcategoryId}/display-name`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, args) => getProvidedTags('Subcategories', args.subcategoryId)
    }),
    updateSubcategoryImageUrl: builder.mutation<boolean, Pto.Categories.ChangeImageUrl & { categoryId: string; subcategoryId: string }>({
      query: ({ categoryId, subcategoryId, ...args }) => ({
        url: `categories/${categoryId}/${subcategoryId}/image-url`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, args) => getProvidedTags('Subcategories', args.subcategoryId)
    }),
    updateSubcategoryIsActive: builder.mutation<boolean, Pto.Categories.ChangeIsActive & { categoryId: string; subcategoryId: string }>({
      query: ({ categoryId, subcategoryId, ...args }) => ({
        url: `categories/${categoryId}/${subcategoryId}/is-active`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, args) => getProvidedTags('Subcategories', args.subcategoryId)
    }),
    updateSubcategorySeoUrl: builder.mutation<boolean, Pto.Categories.ChangeSeoUrl & { categoryId: string; subcategoryId: string }>({
      query: ({ categoryId, subcategoryId, ...args }) => ({
        url: `categories/${categoryId}/${subcategoryId}/seo-url`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, args) => getProvidedTags('Subcategories', args.subcategoryId)
    }),
    removeSubcategory: builder.mutation<boolean, { categoryId: string; subcategoryId: string }>({
      query: ({ categoryId, subcategoryId }) => ({
        url: `categories/${categoryId}/${subcategoryId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { categoryId }) => [{ type: 'Categories', id: categoryId }]
    }),
    addSubcategoryAttribute: builder.mutation<boolean, Pto.Categories.AddAttribute & { categoryId: string; subcategoryId: string }>({
      query: ({ categoryId, subcategoryId, ...args }) => ({
        url: `categories/${categoryId}/${subcategoryId}/attribute`,
        body: args,
        method: 'PUT'
      }),
      invalidatesTags: (result, error, args) => getProvidedTags('Subcategories', args.subcategoryId)
    }),
    removeSubcategoryAttribute: builder.mutation<boolean, { categoryId: string; subcategoryId: string; attributeId: string }>({
      query: ({ categoryId, subcategoryId, attributeId }) => ({
        url: `categories/${categoryId}/${subcategoryId}/attribute/${attributeId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, args) => getProvidedTags('Subcategories', args.subcategoryId)
    }),

    categoriesOptions: builder.query<Pto.Option[], string>({
      query: (searchText) => ({
        url: '/categories/options',
        params: { searchText }
      }),
      providesTags: (categoriesOptionsData, _error, _args) =>
        getProvidedTags(
          'Categories',
          'OPTIONS',
          categoriesOptionsData?.map((item) => item.value)
        )
    }),
    subcategoriesOptions: builder.query<Pto.Option[], { categoryId: string }>({
      query: ({ categoryId }) => ({
        url: '/categories/subcategories/options',
        params: { categoryId }
      }),
      providesTags: (subcategoriesOptionsData, _error, _args) =>
        getProvidedTags(
          'Subcategories',
          'OPTIONS',
          subcategoriesOptionsData?.map((item) => item.value)
        )
    })
  })
})

export const {
  useCategoryListQuery,
  useCategoryQuery,
  useCreateCategoryMutation,
  useUpdateCategoryNameMutation,
  useUpdateCategoryDisplayNameMutation,
  useUpdateCategoryImageUrlMutation,
  useUpdateCategoryIsActiveMutation,
  useUpdateCategorySeoUrlMutation,
  useAddCategoryAttributeMutation,
  useRemoveCategoryAttributeMutation,
  useSubcategoryQuery,
  useCreateSubcategoryMutation,
  useUpdateSubcategoryNameMutation,
  useUpdateSubcategoryDisplayNameMutation,
  useUpdateSubcategoryImageUrlMutation,
  useUpdateSubcategoryIsActiveMutation,
  useUpdateSubcategorySeoUrlMutation,
  useRemoveSubcategoryMutation,
  useRemoveSubcategoryAttributeMutation,
  useAddSubcategoryAttributeMutation,
  useCategoriesOptionsQuery,
  useSubcategoriesOptionsQuery
} = categoriesApi
