import { useState } from 'react'
import { Switch } from 'antd'

import styles from './EditableSwitch.module.scss'

type Props = {
  checkedChildren: any
  unCheckedChildren: any
  sourceValue: boolean
  disabled?: boolean
  padding?: boolean
  onSave: (newValue: boolean) => Promise<boolean>
}

export const EditableSwitch = (props: Props) => {
  const { disabled, sourceValue, padding = true, checkedChildren, unCheckedChildren, onSave } = props

  const [isLoading, setIsLoading] = useState(false)

  const handleAsyncConfirm = async (checked: boolean) => {
    setIsLoading(true)
    await onSave(checked)

    setIsLoading(false)
  }

  return (
    <div className={styles.root} data-padding={padding}>
      <Switch disabled={disabled} loading={isLoading} onChange={handleAsyncConfirm} checked={sourceValue} checkedChildren={checkedChildren} unCheckedChildren={unCheckedChildren} />
    </div>
  )
}
