import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type ProductId = string
type SettingId = string
type FulfillmentRuleId = string

type FulfillmentRuleInfo = {
  customGatewaySettingsId: SettingId
  productId: ProductId
  fulfillmentRuleId: FulfillmentRuleId
}

export const customGatewayProductsDataApi = createApi({
  reducerPath: 'customGatewayProductsDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['CustomGatewayProductsData'],
  endpoints: (builder) => ({
    customGatewayProductFields: builder.query<Pto.CustomGatewayProductData.FulfillmentRule[], { productId: ProductId; customGatewaySettingsId: SettingId }>({
      query: ({ customGatewaySettingsId, productId }) => `/custom-gateway-products-data/${customGatewaySettingsId}/${productId}`,
      providesTags: (_result, _error, { productId }) => getProvidedTags('CustomGatewayProductsData', productId)
    }),
    setRuleProduct: builder.mutation<string, Pto.CustomGatewayProductData.SetFulfillmentRuleProduct & FulfillmentRuleInfo>({
      query: ({ customGatewaySettingsId, productId, fulfillmentRuleId, ...body }) => ({
        url: `/custom-gateway-products-data/${customGatewaySettingsId}/${productId}/${fulfillmentRuleId}/product`,
        body,
        method: 'POST'
      }),
      invalidatesTags: (_result, _error, { productId }) => [{ type: 'CustomGatewayProductsData', id: productId }]
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Product v2') {
    store.dispatch(customGatewayProductsDataApi.util.invalidateTags([{ type: 'CustomGatewayProductsData', id: payload.entityId }]))
  }
}

export const subscribeToCustomGatewayProductEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromCustomGatewayProductEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const { useCustomGatewayProductFieldsQuery, useSetRuleProductMutation } = customGatewayProductsDataApi
