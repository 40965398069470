import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const attributesApi = createApi({
  reducerPath: 'attributeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Attributes'],
  endpoints: (builder) => ({
    attribute: builder.query<Pto.Attributes.Attribute, string>({
      query: (attributeId) => ({
        url: `/attributes/${attributeId}`
      }),
      providesTags: (_result, _error, attributeId) => getProvidedTags('Attributes', attributeId)
    }),
    attributeOptions: builder.query<Pto.Option[], Pto.Attributes.AttributeOptionsQuery>({
      query: (params) => ({
        url: `/attributes/options`,
        params
      })
    }),
    attributeList: builder.query<Pto.Attributes.List, Pto.Attributes.AttributeListQuery>({
      query: (params) => ({
        url: 'attributes',
        params
      }),
      providesTags: (attributeListData, _error, _args) =>
        getProvidedTags(
          'Attributes',
          'LIST',
          attributeListData?.items.map((item) => item.id)
        )
    }),
    createAttribute: builder.mutation<string, Pto.Attributes.Create>({
      query: (createAttributeDto) => ({
        url: 'attributes',
        body: createAttributeDto,
        method: 'POST'
      }),
      invalidatesTags: ['Attributes']
    }),
    updateAttributeName: builder.mutation<boolean, Pto.Attributes.ChangeName>({
      query: (args) => ({
        url: 'attributes/name',
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { attributeId }) => [{ type: 'Attributes', id: attributeId }]
    }),
    updateAttributeDisplayName: builder.mutation<boolean, Pto.Attributes.ChangeDisplayName>({
      query: (args) => ({
        url: 'attributes/display-name',
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { attributeId }) => [{ type: 'Attributes', id: attributeId }]
    }),
    updateAttributeValueType: builder.mutation<boolean, Pto.Attributes.ChangeValueType>({
      query: (args) => ({
        url: 'attributes/value-type',
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { attributeId }) => [{ type: 'Attributes', id: attributeId }]
    }),
    removeAttribute: builder.mutation<void, string>({
      query: (id) => ({
        url: `attributes/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Attributes', id }]
    })
  })
})

export const {
  useAttributeQuery,
  useAttributeOptionsQuery,
  useAttributeListQuery,
  useCreateAttributeMutation,
  useUpdateAttributeDisplayNameMutation,
  useUpdateAttributeNameMutation,
  useUpdateAttributeValueTypeMutation,
  useRemoveAttributeMutation
} = attributesApi
