'use client'

import { ReactNode, Suspense, useCallback, useEffect } from 'react'
import { Pto } from '@merchx-v3/pto'
import { useImmer } from 'use-immer'

import { SupplierContext } from './context'
import { useSupplierQuery } from 'app/api/suppliers-api'
import { Loading } from 'components/Loading'
import { NotFoundPage } from 'components'

export type ProviderProps = {
  supplierId: string
  supplierCenterId: string

  children: ReactNode
}

export interface SupplierContextProps {
  supplierId: string
  supplierCenterId: string

  supplier: Pto.Suppliers.Supplier
  supplierCenter: Pto.Suppliers.SupplierCenter
  supplierCenters: Pto.Suppliers.SupplierCenter[]
  settings: Pto.Suppliers.SupplierCenterSettings

  setActiveSupplierCenter: (supplierCenterId: string) => void
}

type LocalState = {
  isLoading: boolean
  supplier?: Pto.Suppliers.Supplier
  supplierCenter?: Pto.Suppliers.SupplierCenter

  supplierCenters: Pto.Suppliers.SupplierCenter[]

  settings: Pto.Suppliers.SupplierCenterSettings
}

const defaultSettings: Pto.Suppliers.SupplierCenterSettings = {
  supplierOrderFlow: Pto.Suppliers.SupplierOrderFlow.Manual
}

export const SupplierContextProvider = ({ supplierId, supplierCenterId, children }: ProviderProps): JSX.Element => {
  const { data: supplier, isLoading: isSupplierLoading } = useSupplierQuery(supplierId, { skip: !supplierId })

  const [localState, setLocalState] = useImmer<LocalState>({
    isLoading: isSupplierLoading,
    supplier: undefined,
    supplierCenter: undefined,
    supplierCenters: [],
    settings: defaultSettings
  })

  useEffect(() => {
    setLocalState((draft) => {
      draft.supplier = supplier
      draft.supplierCenter = supplier?.centers.find((center) => center.id === supplierCenterId)
      draft.supplierCenters = supplier?.centers || []
      draft.settings = supplier
        ? {
            supplierOrderFlow: supplier.flow
          }
        : defaultSettings
    })
  }, [setLocalState, supplier, supplierCenterId])

  useEffect(() => {
    setLocalState((draft) => {
      draft.isLoading = isSupplierLoading
    })
  }, [isSupplierLoading, setLocalState])

  const setActiveSupplierCenter = useCallback(
    (supplierCenterId: string) => {
      setLocalState((draft) => {
        localStorage.setItem('supplierCenterId', supplierCenterId)
        draft.supplierCenter = draft.supplierCenters.find((item) => item.id === supplierCenterId)
      })
    },
    [setLocalState]
  )

  if (localState.isLoading) return <Loading />

  if (!localState.supplier || !localState.supplierCenter) return <NotFoundPage />

  return (
    <SupplierContext.Provider
      value={{
        supplierId: localState.supplier.id,
        supplierCenterId: localState.supplierCenter.id,

        supplier: localState.supplier,
        supplierCenter: localState.supplierCenter,
        supplierCenters: localState.supplierCenters,
        settings: localState.settings,

        setActiveSupplierCenter
      }}
    >
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </SupplierContext.Provider>
  )
}
