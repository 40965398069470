import { v4 } from 'uuid'
import { Pto } from '@merchx-v3/pto'
import { toast } from 'react-toastify'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { Divider, Input, Form, Modal, Select, Checkbox, Switch } from 'antd'
import { useCreateSupplierCenterMutation } from 'app/api/suppliers-api'
import { ShippingSystemSettingsSelect } from 'interfaces/sharedComponents'
import { SupplierPluginOptions } from 'interfaces/admin/features/plugins/components'

import styles from './AddSupplierCenterModal.module.scss'

interface FormFields {
  name: string
  autoconfirmOrder: boolean
  shippingPlugin: Pto.Option
  shippingSystemType: Pto.SupplierOrderShippings.ShippingSystemType
  shippingSystemId?: string
  country: string
  state: string
  city: string
  zipCode: string
  address1: string
  address2: string
  weekWorkingSchedule?: number[]
}

type WeekWorkingSchedule = [boolean, boolean, boolean, boolean, boolean, boolean, boolean]

type Props = {
  supplierId?: string
  isOpened: boolean
  onClose: () => void
}

const AddSupplierCenterModal = ({ supplierId, isOpened, onClose }: Props) => {
  const [form] = Form.useForm<FormFields>()

  const shippingSystemType = Form.useWatch(['shippingSystemType'], form)

  const [createSupplierCenter, { isLoading }] = useCreateSupplierCenterMutation()

  const handleCloseModal = () => {
    onClose()
    form.resetFields()
  }

  const handleFinish = async (values: FormFields) => {
    if (!supplierId) {
      toast.error('Supplier was not found')
      return
    }

    const weekWorkingSchedule = [...Array(7)].map((_) => false) as WeekWorkingSchedule
    for (const day of values.weekWorkingSchedule || []) {
      weekWorkingSchedule[day] = true
    }

    const data: Pto.Suppliers.CreateUpdateSupplierCenter = {
      id: v4(),
      name: values.name,
      supplierId,
      autoconfirmOrder: values.autoconfirmOrder,
      address: {
        country: values.country,
        state: values.state,
        city: values.city,
        zipCode: values.zipCode,
        address1: values.address1,
        address2: values.address2
      },
      weekWorkingSchedule,
      shippingPluginId: values.shippingPlugin?.value,
      shippingSystemId: values.shippingSystemId,
      shippingSystemType: values.shippingSystemType
    }

    await createSupplierCenter(data)
      .unwrap()
      .then(() => toast.success('Supplier was created successfully!'))
      .catch((error) => toast.error(error))

    handleCloseModal()
  }

  const handleFinishFailed = (errorInfo: ValidateErrorEntity) => {
    toast.error(errorInfo?.errorFields?.map((errField) => errField.errors.join('\n')).join('\n'))
  }

  return (
    <Modal
      forceRender
      width={960}
      open={isOpened}
      onCancel={handleCloseModal}
      okText="Save"
      onOk={form.submit}
      destroyOnClose
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ loading: isLoading }}
      maskClosable={!isLoading}
      title="Create New Center"
    >
      <Form
        name="addSupplierCenter"
        layout="vertical"
        form={form}
        style={{ width: '100%' }}
        initialValues={{ remember: true }}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        autoComplete="off"
        disabled={isLoading}
      >
        <div className={styles.formBox}>
          <div className={styles.infoRow}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input supplier center name!' }]}>
              <Input placeholder="Supplier Name Example" />
            </Form.Item>

            <Form.Item label="Shipping plugin" name="shippingPlugin">
              <SupplierPluginOptions supplierId={supplierId} pluginType={Pto.Plugins.PluginType.Shipping} placeholder="Select Shipping Plugin" />
            </Form.Item>

            <Form.Item label="Autoconfirm Order" name="autoconfirmOrder" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
          <Divider />
          <div className={styles.infoRow}>
            <Form.Item label="Shipping System Type" name="shippingSystemType" rules={[{ required: true, message: 'Please select shipping system type!' }]}>
              <Select options={Object.values(Pto.SupplierOrderShippings.ShippingSystemType).map((value) => ({ label: value, value }))} />
            </Form.Item>

            <Form.Item label="Shipping System ID" name="shippingSystemId">
              <ShippingSystemSettingsSelect supplierId={supplierId!} supplierCenterId={''} shippingSystemType={shippingSystemType} placeholder="Select Shipping System" />
            </Form.Item>
          </div>
          <Divider />
          <div className={styles.infoRow}>
            <Form.Item label="Address Line 1" name="address1" rules={[{ required: true, message: 'Please input Address!' }]}>
              <Input placeholder="Address Line 1" />
            </Form.Item>
            <Form.Item label="Address Line 2" name="address2">
              <Input placeholder="Address Line 2" />
            </Form.Item>
          </div>

          <div className={styles.infoRow}>
            <Form.Item label="Country" name="country" rules={[{ required: true, message: 'Please input country!' }]}>
              <Select placeholder="Country" options={Pto.Addresses.Countries} />
            </Form.Item>
            <Form.Item label="City" name="city" rules={[{ required: true, message: 'Please input city!' }]}>
              <Input placeholder="City" />
            </Form.Item>
          </div>

          <div className={styles.infoRow}>
            <Form.Item label="State" name="state" rules={[{ required: true, message: 'Please input state!' }]}>
              <Select placeholder="State" options={Pto.Addresses.Countries[0].states} />
            </Form.Item>
            <Form.Item label="Zip" name="zipCode" rules={[{ required: true, message: 'Please input zip!' }]}>
              <Input placeholder="Zip" />
            </Form.Item>
          </div>

          <Form.Item label="Week Working Schedule" name="weekWorkingSchedule">
            <Checkbox.Group>
              <Checkbox value={0}>Sunday</Checkbox>
              <Checkbox value={1}>Monday</Checkbox>
              <Checkbox value={2}>Tuesday</Checkbox>
              <Checkbox value={3}>Wednesday</Checkbox>
              <Checkbox value={4}>Thursday</Checkbox>
              <Checkbox value={5}>Friday</Checkbox>
              <Checkbox value={6}>Saturday</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>
        <Divider />
      </Form>
    </Modal>
  )
}

export default AddSupplierCenterModal
