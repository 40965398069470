import { Pto } from '@merchx-v3/pto'
import { toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'

import { authProvider } from './auth/auth-provider'
import { tokenProvider } from './auth/token-provider'

const axiosInstance = axios.create({ baseURL: '/api', timeout: 60000 })

axiosInstance.interceptors.request.use(async (config) => {
  const token = await tokenProvider.getAccessToken()

  if (token) {
    config.headers.Authorization = token
  }

  return config
})

axiosInstance.interceptors.response.use(null, async (error: AxiosError): Promise<any> => {
  if (error.response?.status === 401) {
    try {
      const refreshToken = tokenProvider.getRefreshToken()
      const response = await axios.post<Pto.Auth.Session>('/api/session/refresh', { refreshToken })
      tokenProvider.setAccessToken(response.data.token)
      tokenProvider.setRefreshToken(response.data.refreshToken)

      error.response.config.headers.authorization = response.data.token
      const res = await axiosInstance(error.response.config)
      return res
    } catch (exception: any) {
      exception.response?.status === 401 && authProvider.signOut()
      return error
    }
  }

  toast.error(error.message)

  throw error
})

export default axiosInstance
