import { ElementRef, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Form, Input, InputNumber, Modal, Select, Button } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'
import { Pto } from '@merchx-v3/pto'
import { EditableSwitch } from '../../../components/EditableSwitch'
import { useProjectQuery } from 'app/api/projects-api'
import PluginFields from '../PluginFields'

import styles from './ShippingTaskInfoModal.module.scss'

import { runMutation } from '../../../shared/run-mutation'
import { useUpdateIsManualShippingPriceMutation, useUpdateShippingTaskInfoMutation } from 'app/api/shipments-api'
import { useShippingTaskPluginFieldsQuery } from 'app/api/plugins-api'

type Props = {
  shippingTask: Pto.Shipments.ShippingTask
  projectId: string

  isLoading: boolean
  isOpened: boolean
  onClose: () => void
}

export type FormFields = {
  shippingAddress: Pto.Addresses.ShippingAddress
  dimension: Pto.Shipments.OrderDimension
  weight: {
    lb: number
    oz: number
  }
  customerEmail: string
  customerFirstName: string
  customerLastName: string
  shippingPrice: number
  shippingNote: string

  pluginFields: Pto.Plugins.Fields.IShippingTaskField[]
}

const ShippingTaskInfoModal = (props: Props) => {
  const { shippingTask, projectId, isLoading, isOpened, onClose } = props

  const [pluginFieldsToEdit, setPluginFieldsToEdit] = useState<Pto.Plugins.Fields.IShippingTaskField[]>([])
  const { data: project } = useProjectQuery(projectId)
  const { data: shippingTaskPluginFields, isLoading: isPluginFieldsLoading } = useShippingTaskPluginFieldsQuery(
    {
      shippingTaskId: shippingTask?.id || '',
      supplierId: shippingTask?.supplierId || '',
      pluginId: shippingTask?.shippingPluginId || ''
    },
    { skip: !shippingTask?.shippingPluginId }
  )

  const [updateShippingTaskInfo, { isLoading: isUpdatingShippingInfo }] = useUpdateShippingTaskInfoMutation()
  const [updateIsManualShippingPrice, { isLoading: isUpdateIsManualShippingPriceLoading }] = useUpdateIsManualShippingPriceMutation()

  const [form] = Form.useForm<FormFields>()
  const pluginFieldsRef = useRef<ElementRef<typeof PluginFields>>(null)

  useEffect(() => {
    if (!shippingTaskPluginFields) {
      setPluginFieldsToEdit([])
      return
    }

    setPluginFieldsToEdit(shippingTaskPluginFields.pluginFields.shippingTask)
  }, [shippingTaskPluginFields])

  useEffect(() => {
    if (!project || !shippingTask) return

    const shippingAddress: Pto.Addresses.ShippingAddress = {
      firstName: shippingTask.shippingAddress.firstName || project.shippingAddress?.firstName || '',
      lastName: shippingTask.shippingAddress.lastName || project.shippingAddress?.lastName || '',
      zipCode: shippingTask.shippingAddress.zipCode || project.shippingAddress?.zipCode || '',
      country: shippingTask.shippingAddress.country || project.shippingAddress?.country || '',
      state: shippingTask.shippingAddress.state || project.shippingAddress?.state || '',
      city: shippingTask.shippingAddress.city || project.shippingAddress?.city || '',
      address1: shippingTask.shippingAddress.address1 || project.shippingAddress?.address1 || '',
      address2: shippingTask.shippingAddress.address2 || project.shippingAddress?.address2 || ''
    }

    const dimension: Pto.Shipments.OrderDimension = {
      dimensionUnit: shippingTask.dimension.dimensionUnit || Pto.Shipments.DimensionUnit.Inches,
      width: shippingTask.dimension.width,
      height: shippingTask.dimension.height,
      length: shippingTask.dimension.length
    }

    form.setFieldsValue({
      shippingAddress,
      dimension,
      customerEmail: project.customerEmail,
      customerFirstName: project.customerFirstName,
      customerLastName: project.customerLastName,
      shippingPrice: shippingTask?.shippingPrice !== undefined ? shippingTask.shippingPrice / 100 : undefined,
      shippingNote: shippingTask.shippingNote,
      weight: {
        lb: shippingTask.weight.weightUnit === Pto.WeightUnit.Pounds ? shippingTask.weight.weight : 0,
        oz: shippingTask.weight.weightUnit === Pto.WeightUnit.Ounces ? shippingTask.weight.weight : 0
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, shippingTask])

  const handleFinishFailed = (errorInfo: ValidateErrorEntity) => {
    toast.error(errorInfo?.errorFields?.map((errField) => errField.errors.join('\n')).join('\n'))
  }

  const handlePluginFieldsChanged = (fields: Pto.Plugins.Fields.IField[]) => {
    setPluginFieldsToEdit(fields)
  }

  const handleOk = () => {
    form.submit()
  }

  const handleFinish = async (values: FormFields) => {
    if (!shippingTask) return

    const isValid = pluginFieldsRef.current?.validate()

    if (!isValid) return

    const saveFieldValues: Pto.Plugins.Fields.SaveFieldValues[] = []

    if (shippingTask.shippingPluginId && pluginFieldsToEdit.length) {
      saveFieldValues.push({
        supplierId: shippingTask.supplierId,
        pluginId: shippingTask.shippingPluginId,
        pluginType: Pto.Plugins.PluginType.Shipping,
        values: [{ key: shippingTask.id, fields: pluginFieldsToEdit }]
      })
    }

    await updateShippingTaskInfo({
      shippingTask: {
        shipmentId: shippingTask.shipmentId,
        shippingTaskId: shippingTask.id,

        shippingAddress: values.shippingAddress,

        customerEmail: values.customerEmail,
        customerFirstName: values.customerFirstName,
        customerLastName: values.customerLastName,
        shippingPrice: Math.round((values.shippingPrice || 0) * 100),

        weight: {
          weight: values.weight.lb * 16 + values.weight.oz,
          weightUnit: Pto.WeightUnit.Ounces
        },
        shippingNote: values.shippingNote || '',
        dimension: values.dimension
      },
      pluginFields: saveFieldValues
    })
      .unwrap()
      .then(() => {
        toast.success(`Shipping task (${shippingTask.id}) info was updated`)
        onClose()
      })
      .catch((error) => toast.error(error))
  }

  const handleUpdateIsManualShippingPrice = async (isManualShippingPrice: boolean) => {
    if (!shippingTask) return false

    const { isSuccessful } = await runMutation(
      () => updateIsManualShippingPrice({ shippingTaskId: shippingTask.id, shipmentId: shippingTask.shipmentId, isManualShippingPrice }),
      'Shipping price behavior updated'
    )

    return isSuccessful
  }

  const internalLoading = isLoading || isUpdatingShippingInfo || isPluginFieldsLoading || isUpdateIsManualShippingPriceLoading

  return (
    <Modal forceRender open={isOpened} onCancel={onClose} onOk={handleOk} footer={false} title="Update Shiping info" centered width={850}>
      <Form
        name={`updateShippingTaskInfo-${shippingTask.id}`}
        disabled={internalLoading}
        form={form}
        className={styles.form}
        layout="vertical"
        onFinishFailed={handleFinishFailed}
        onFinish={handleFinish}
      >
        <Form.Item
          hidden
          initialValue={Pto.Shipments.DimensionUnit.Inches}
          name={['dimension', 'dimensionUnit']}
          rules={[{ required: true, message: 'Please input dimension unit!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item hidden name="customerEmail" rules={[{ required: true, message: 'Please input customer email!' }]}>
          <Input />
        </Form.Item>

        <Form.Item hidden name="customerFirstName" rules={[{ required: true, message: 'Please input customer first name!' }]}>
          <Input />
        </Form.Item>

        <Form.Item hidden name="customerLastName" rules={[{ required: true, message: 'Please input customer last name!' }]}>
          <Input />
        </Form.Item>

        <div className={styles.row}>
          <Form.Item label="First Name" name={['shippingAddress', 'firstName']} rules={[{ required: true, message: 'Please input customer first name!' }]}>
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item label="Last Name" name={['shippingAddress', 'lastName']} rules={[{ required: true, message: 'Please input customer last name!' }]}>
            <Input placeholder="Last Name" />
          </Form.Item>
        </div>

        <div className={styles.row}>
          <Form.Item label="Address Line 1" name={['shippingAddress', 'address1']} rules={[{ required: true, message: 'Please input Address!' }]}>
            <Input placeholder="Address Line 1" />
          </Form.Item>
          <Form.Item label="Address Line 2" name={['shippingAddress', 'address2']}>
            <Input placeholder="Address Line 2" />
          </Form.Item>
        </div>

        <div className={styles.row}>
          <Form.Item label="Country" name={['shippingAddress', 'country']} rules={[{ required: true, message: 'Please input country!' }]}>
            <Select placeholder="Country" options={Pto.Addresses.Countries} />
          </Form.Item>
          <Form.Item label="City" name={['shippingAddress', 'city']} rules={[{ required: true, message: 'Please input city!' }]}>
            <Input placeholder="City" />
          </Form.Item>
        </div>

        <div className={styles.row}>
          <Form.Item label="State" name={['shippingAddress', 'state']} rules={[{ required: true, message: 'Please input state!' }]}>
            <Select placeholder="State" options={Pto.Addresses.Countries[0].states} />
          </Form.Item>
          <Form.Item label="Zip" name={['shippingAddress', 'zipCode']} rules={[{ required: true, message: 'Please input zip!' }]}>
            <Input placeholder="Zip" />
          </Form.Item>
        </div>

        <div className={styles.divider} />

        <div className={styles.rowGridBox}>
          <Form.Item label="Size">
            <div className={styles.rowGrid}>
              <Form.Item label="L" name={['dimension', 'length']} rules={[{ required: true, message: 'Please input size length!' }]}>
                <InputNumber placeholder="length" style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item label="W" name={['dimension', 'width']} rules={[{ required: true, message: 'Please input size width!' }]}>
                <InputNumber placeholder="width" style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item label="H (in)" name={['dimension', 'height']} rules={[{ required: true, message: 'Please input size height!' }]}>
                <InputNumber placeholder="height" style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </Form.Item>
        </div>

        <div className={styles.divider} />

        <div className={styles.rowGridBox}>
          <Form.Item label="Weight">
            <div className={styles.rowGrid}>
              <Form.Item label="(lb)" name={['weight', 'lb']} initialValue={0} rules={[{ required: false }]}>
                <InputNumber placeholder="lb" style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item label="(oz)" name={['weight', 'oz']} initialValue={0} rules={[{ required: false }]}>
                <InputNumber placeholder="oz" style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </Form.Item>
        </div>

        <div className={styles.divider} />

        <div className={styles.rowGridBox}>
          <Form.Item label="Price">
            <div className={styles.rowGrid}>
              <EditableSwitch
                onSave={(val) => handleUpdateIsManualShippingPrice(val)}
                sourceValue={!!shippingTask?.isManualShippingPrice}
                disabled={shippingTask?.status !== Pto.Shipments.ShippingTaskStatus.New}
                checkedChildren="Manual price"
                unCheckedChildren="Automated price"
              />
              <Form.Item label="Price" name="shippingPrice" rules={[{ required: true, message: 'Please input shipping price!' }]} style={{ gridColumn: '2/4' }}>
                <InputNumber
                  className={styles.shippingPriceInput}
                  style={{ width: '100%' }}
                  disabled={!shippingTask?.isManualShippingPrice || shippingTask?.status !== Pto.Shipments.ShippingTaskStatus.New}
                  min={0}
                  prefix="$"
                  precision={2}
                  changeOnWheel={false}
                />
              </Form.Item>
            </div>
          </Form.Item>
        </div>

        <div className={styles.divider} />

        <div className={styles.rowGridBox}>
          <Form.Item label="Shipping Note" name="shippingNote" rules={[{ required: false }]}>
            <Input placeholder="Shipping Note" />
          </Form.Item>
        </div>

        <div className={styles.divider} />

        {shippingTask.shippingPluginId && (
          <div className={styles.rowGridBox}>
            <PluginFields
              ref={pluginFieldsRef}
              disabled={internalLoading}
              targetId={shippingTask.id}
              pluginInfo={{ value: shippingTask.shippingPluginId, label: shippingTask.shippingPluginName! }}
              fields={pluginFieldsToEdit}
              supplierId={shippingTask.supplierId}
              showTitle
              onChange={(newValue) => handlePluginFieldsChanged(newValue)}
            />
          </div>
        )}

        <div className={styles.btn}>
          <Button type="primary" size="large" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ShippingTaskInfoModal
