import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type ShippingArgs = {
  shippingId: string
}

type ShippingTaskArgs = {
  shippingId: string
  shippingTaskId: string
}

export const supplierOrderShippingsApi = createApi({
  reducerPath: 'supplierOrderShippingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['SupplierOrderShippings', 'SupplierOrderShippingTasks'],

  endpoints: (builder) => ({
    supplierOrderShipping: builder.query<Pto.SupplierOrderShippings.SupplierOrderShipping, string>({
      query: (shippingId) => `/supplier-order-shippings/${shippingId}`,
      providesTags: (_result, _error, shippingId) => getProvidedTags('SupplierOrderShippings', shippingId)
    }),

    supplierOrderShippingList: builder.query<Pto.SupplierOrderShippings.List, Pto.SupplierOrderShippings.ListArgs>({
      query: (params) => ({
        url: 'supplier-order-shippings',
        params: params
      }),
      providesTags: (supplierOrderShippingListData, _error, _args) =>
        getProvidedTags(
          'SupplierOrderShippings',
          'LIST',
          supplierOrderShippingListData?.items.map((item) => item.id)
        )
    }),
    supplierOrderShippingListBadges: builder.query<Pto.SupplierOrderShippings.ListBadges, Pto.SupplierOrderShippings.ListBadgeArgs>({
      query: (params) => ({
        url: 'supplier-order-shippings/list-badges',
        params
      })
    }),

    sendToShipping: builder.mutation<void, string>({
      query: (shippingId) => ({
        url: `/supplier-order-shippings/${shippingId}/send-to-shipping`,
        method: 'POST'
      })
    }),

    startTaskProcessing: builder.mutation<void, ShippingTaskArgs>({
      query: ({ shippingId, shippingTaskId }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/start-processing`,
        method: 'PATCH'
      })
    }),

    completeTask: builder.mutation<void, ShippingTaskArgs>({
      query: ({ shippingId, shippingTaskId }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/complete`,
        method: 'PATCH'
      })
    }),

    cancelTask: builder.mutation<void, ShippingTaskArgs & Pto.SupplierOrderShippings.Commands.Args.CancelTask>({
      query: ({ shippingId, shippingTaskId, reason }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/cancel`,
        method: 'PATCH',
        body: { reason }
      })
    }),

    holdTask: builder.mutation<void, ShippingTaskArgs & Pto.SupplierOrderShippings.Commands.Args.HoldTask>({
      query: ({ shippingId, shippingTaskId, reason }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/hold`,
        method: 'PATCH',
        body: { reason }
      })
    }),

    unholdTask: builder.mutation<void, ShippingTaskArgs>({
      query: ({ shippingId, shippingTaskId }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/unhold`,
        method: 'PATCH'
      })
    }),

    changeTrackingNumber: builder.mutation<void, ShippingTaskArgs & Pto.SupplierOrderShippings.Commands.Args.ChangeTrackingNumber>({
      query: ({ shippingId, shippingTaskId, ...body }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/tracking-number`,
        method: 'PATCH',
        body
      })
    }),

    getTaskStatusCounts: builder.query<Pto.SupplierOrderShippings.Tasks.TaskStatusCount, void>({
      query: () => ({
        url: `/supplier-order-shipping-tasks/status-counts`,
        method: 'GET'
      }),
      providesTags: ['SupplierOrderShippingTasks']
    }),

    supplierOrderShippingTaskList: builder.query<Pto.SupplierOrderShippings.Tasks.List, Pto.SupplierOrderShippings.Tasks.ListArgs>({
      query: (listArgs) => ({
        url: `supplier-order-shipping-tasks`,
        params: listArgs,
        method: 'GET'
      }),
      providesTags: (shippingTaskListData, _error, _args) =>
        getProvidedTags(
          'SupplierOrderShippingTasks',
          'LIST',
          shippingTaskListData?.items.map((item) => item.supplierOrderId)
        )
    }),

    supplierOrderShippingTask: builder.query<Pto.SupplierOrderShippings.Tasks.Details, string>({
      query: (shippingTaskId) => `/supplier-order-shipping-tasks/${shippingTaskId}`,
      providesTags: (result, _error, _shippingTaskId) => (result ? getProvidedTags('SupplierOrderShippings', result.supplierOrderId) : [])
    }),

    completeShipping: builder.mutation<void, ShippingArgs>({
      query: ({ shippingId }) => ({
        url: `/supplier-order-shippings/${shippingId}/complete`,
        method: 'PATCH'
      })
    }),

    holdShipping: builder.mutation<void, ShippingArgs & Pto.SupplierOrderShippings.Hold>({
      query: ({ shippingId, reason }) => ({
        url: `/supplier-order-shippings/${shippingId}/hold`,
        method: 'PATCH',
        body: { reason }
      })
    }),

    takeResponsibility: builder.mutation<string, { shippingId: string }>({
      query: ({ shippingId }) => ({
        url: `/supplier-order-shippings/${shippingId}/responsible-users`,
        method: 'PUT'
      })
    }),
    dropResponsibility: builder.mutation<string, { shippingId: string }>({
      query: ({ shippingId }) => ({
        url: `/supplier-order-shippings/${shippingId}/responsible-users`,
        method: 'DELETE'
      })
    }),
    addResponsible: builder.mutation<string, { shippingId: string; responsibleUserId: string }>({
      query: ({ shippingId, responsibleUserId }) => ({
        url: `/supplier-order-shippings/${shippingId}/responsible-users/${responsibleUserId}`,
        method: 'PUT'
      })
    }),
    removeResponsible: builder.mutation<string, { shippingId: string; responsibleUserId: string }>({
      query: ({ shippingId, responsibleUserId }) => ({
        url: `/supplier-order-shippings/${shippingId}/responsible-users/${responsibleUserId}`,
        method: 'DELETE'
      })
    }),
    updateSupplierOrderShippingComment: builder.mutation<string, { shippingId: string; comment: string }>({
      query: ({ shippingId, comment }) => ({
        url: `/supplier-order-shippings/${shippingId}/comment`,
        body: { comment },
        method: 'PATCH'
      })
    }),
    takeResponsibilityForTask: builder.mutation<string, { shippingId: string, shippingTaskId: string }>({
      query: ({ shippingId, shippingTaskId }) => ({
        url: `/supplier-order-shippings/${shippingId}/${shippingTaskId}/responsible-users`,
        method: 'PUT'
      })
    }),
    dropResponsibilityForTask: builder.mutation<string, { shippingId: string, shippingTaskId: string }>({
      query: ({ shippingId, shippingTaskId }) => ({
        url: `/supplier-order-shippings/${shippingId}/${shippingTaskId}/responsible-users`,
        method: 'DELETE'
      })
    }),
    addResponsibleForTask: builder.mutation<string, { shippingId: string; responsibleUserId: string; shippingTaskId: string }>({
      query: ({ shippingId, shippingTaskId, responsibleUserId }) => ({
        url: `/supplier-order-shippings/${shippingId}/${shippingTaskId}/responsible-users/${responsibleUserId}`,
        method: 'PUT'
      })
    }),
    removeResponsibleForTask: builder.mutation<string, { shippingId: string; responsibleUserId: string; shippingTaskId: string }>({
      query: ({ shippingId, shippingTaskId, responsibleUserId }) => ({
        url: `/supplier-order-shippings/${shippingId}/${shippingTaskId}/responsible-users/${responsibleUserId}`,
        method: 'DELETE'
      })
    }),
    updateSupplierOrderShippingTaskComment: builder.mutation<string, { shippingId: string; comment: string; shippingTaskId: string }>({
      query: ({ shippingId, shippingTaskId, comment }) => ({
        url: `/supplier-order-shippings/${shippingId}/${shippingTaskId}/comment`,
        body: { comment },
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Supplier order shipping') {
    store.dispatch(supplierOrderShippingsApi.util.invalidateTags([{ type: 'SupplierOrderShippings', id: payload.entityId }]))
  }
}

export const subscribeToSupplierOrderShippingsEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSupplierOrderShippingsEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useSupplierOrderShippingListQuery,
  useSupplierOrderShippingListBadgesQuery,
  useSupplierOrderShippingQuery,
  useSendToShippingMutation,
  useStartTaskProcessingMutation,
  useCompleteTaskMutation,
  useCancelTaskMutation,
  useHoldTaskMutation,
  useUnholdTaskMutation,
  useChangeTrackingNumberMutation,
  useGetTaskStatusCountsQuery,
  useSupplierOrderShippingTaskListQuery,
  useSupplierOrderShippingTaskQuery,
  useCompleteShippingMutation,
  useHoldShippingMutation,
  useTakeResponsibilityMutation,
  useDropResponsibilityMutation,
  useAddResponsibleMutation,
  useRemoveResponsibleMutation,
  useUpdateSupplierOrderShippingCommentMutation,
  useTakeResponsibilityForTaskMutation,
  useDropResponsibilityForTaskMutation,
  useAddResponsibleForTaskMutation,
  useRemoveResponsibleForTaskMutation,
  useUpdateSupplierOrderShippingTaskCommentMutation
} = supplierOrderShippingsApi
