import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type ListArgs = {
  customGatewaySettingsId: string
  searchText?: string
  page?: number
  size?: number
}

type SettingArgs = {
  customGatewaySettingsId: string
}

export const customGatewayBlankProductsApi = createApi({
  reducerPath: 'customGatewayBlankProductsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['CustomGatewayBlankProducts'],
  endpoints: (builder) => ({
    customGatewayBlankProductsList: builder.query<Pto.CustomGatewayBlankProducts.List, ListArgs>({
      query: ({ customGatewaySettingsId, searchText, page = 1, size = 16 }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/list`,
        params: { searchText, page, size }
      }),
      providesTags: (blankProductsListData, _error, { customGatewaySettingsId }) =>
        getProvidedTags(
          'CustomGatewayBlankProducts',
          `${customGatewaySettingsId}-LIST`,
          blankProductsListData?.items.map((item) => item.id)
        )
    }),
    customGatewayBlankProduct: builder.query<Pto.CustomGatewayBlankProducts.BlankProduct, { customGatewaySettingsId: string; blankProductId: string }>({
      query: ({ customGatewaySettingsId, blankProductId }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/${blankProductId}`
      }),
      providesTags: (_result, _error, { customGatewaySettingsId, blankProductId }) => [{ type: 'CustomGatewayBlankProducts', id: `${customGatewaySettingsId}-${blankProductId}` }]
    }),
    customGatewayBlankProductOptions: builder.query<Pto.Option[], Pto.CustomGatewayBlankProducts.OptionArgs & SettingArgs>({
      query: ({ customGatewaySettingsId, searchText, requiredItemId }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/options`,
        params: { searchText, requiredItemId }
      })
    }),
    customGatewayBlankProductVariantOptions: builder.query<Pto.Option[], Pto.CustomGatewayBlankProducts.OptionArgs & { blankProductId: string } & SettingArgs>({
      query: ({ customGatewaySettingsId, blankProductId, searchText, requiredItemId }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/${blankProductId}/variants/options`,
        params: { searchText, requiredItemId }
      })
    }),
    createCustomGatewayBlankProduct: builder.mutation<string, Pto.CustomGatewayBlankProducts.CreateCustomGatewayBlankProduct & SettingArgs>({
      query: (args) => {
        const { customGatewaySettingsId, ...body } = args
        return {
          url: `custom-gateway-blank-products/${customGatewaySettingsId}`,
          body,
          method: 'POST'
        }
      }
    }),
    updateCustomGatewayBlankProductName: builder.mutation<boolean, Pto.CustomGatewayBlankProducts.ChangeName & { customGatewaySettingsId: string; blankProductId: string }>({
      query: ({ customGatewaySettingsId, blankProductId, ...args }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/${blankProductId}/name`,
        body: args,
        method: 'PATCH'
      })
    }),
    updateCustomGatewayBlankProductExternalId: builder.mutation<
      boolean,
      Pto.CustomGatewayBlankProducts.ChangeExternalId & { customGatewaySettingsId: string; blankProductId: string }
    >({
      query: ({ customGatewaySettingsId, blankProductId, ...args }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/${blankProductId}/external-id`,
        body: args,
        method: 'PATCH'
      })
    }),
    addCustomGatewayBlankProductVariant: builder.mutation<string, Pto.CustomGatewayBlankProducts.AddVariant & { customGatewaySettingsId: string; blankProductId: string }>({
      query: ({ customGatewaySettingsId, blankProductId, ...body }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/${blankProductId}/variants`,
        body,
        method: 'POST'
      }),
      invalidatesTags: (_result, _error, { customGatewaySettingsId, blankProductId }) => [
        { type: 'CustomGatewayBlankProducts', id: `${customGatewaySettingsId}-${blankProductId}` }
      ]
    }),
    updateCustomGatewayBlankProductVariantName: builder.mutation<
      boolean,
      Pto.CustomGatewayBlankProducts.ChangeVariantName & { customGatewaySettingsId: string; blankProductId: string; variantId: string }
    >({
      query: ({ customGatewaySettingsId, blankProductId, variantId, name }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/${blankProductId}/variants/${variantId}/name`,
        body: { name },
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { customGatewaySettingsId, blankProductId }) => [
        { type: 'CustomGatewayBlankProducts', id: `${customGatewaySettingsId}-${blankProductId}` }
      ]
    }),
    updateCustomGatewayBlankProductVariantExternalId: builder.mutation<
      boolean,
      Pto.CustomGatewayBlankProducts.ChangeVariantExternalId & { customGatewaySettingsId: string; blankProductId: string; variantId: string }
    >({
      query: ({ customGatewaySettingsId, blankProductId, variantId, externalId }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/${blankProductId}/variants/${variantId}/external-id`,
        body: { externalId },
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { customGatewaySettingsId, blankProductId }) => [
        { type: 'CustomGatewayBlankProducts', id: `${customGatewaySettingsId}-${blankProductId}` }
      ]
    }),
    removeCustomGatewayBlankProductVariant: builder.mutation<boolean, { customGatewaySettingsId: string; blankProductId: string; variantId: string }>({
      query: ({ customGatewaySettingsId, blankProductId, variantId }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/${blankProductId}/variants/${variantId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { customGatewaySettingsId, blankProductId }) => [
        { type: 'CustomGatewayBlankProducts', id: `${customGatewaySettingsId}-${blankProductId}` }
      ]
    }),
    updateCustomGatewayBlankProductStatus: builder.mutation<boolean, Pto.CustomGatewayBlankProducts.ChangeStatus & { customGatewaySettingsId: string; blankProductId: string }>({
      query: ({ customGatewaySettingsId, blankProductId, ...args }) => ({
        url: `custom-gateway-blank-products/${customGatewaySettingsId}/${blankProductId}/status`,
        body: args,
        method: 'PATCH'
      })
    })
  })
})

export const {
  useCustomGatewayBlankProductsListQuery,
  useCustomGatewayBlankProductQuery,
  useCustomGatewayBlankProductVariantOptionsQuery,
  useCustomGatewayBlankProductOptionsQuery,
  useCreateCustomGatewayBlankProductMutation,
  useUpdateCustomGatewayBlankProductNameMutation,
  useUpdateCustomGatewayBlankProductExternalIdMutation,
  useAddCustomGatewayBlankProductVariantMutation,
  useUpdateCustomGatewayBlankProductVariantNameMutation,
  useUpdateCustomGatewayBlankProductVariantExternalIdMutation,
  useRemoveCustomGatewayBlankProductVariantMutation,
  useUpdateCustomGatewayBlankProductStatusMutation
} = customGatewayBlankProductsApi
