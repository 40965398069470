import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const supplierOrderDesignsApi = createApi({
  reducerPath: 'supplierOrderDesignsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['SupplierOrderDesigns'],

  endpoints: (builder) => ({
    supplierOrderDesign: builder.query<Pto.SupplierOrderDesigns.SupplierOrderDesign, string>({
      query: (designId) => `/supplier-order-designs/${designId}`,
      providesTags: (_result, _error, designId) => getProvidedTags('SupplierOrderDesigns', designId)
    }),
    supplierOrderDesignList: builder.query<Pto.SupplierOrderDesigns.List, Pto.SupplierOrderDesigns.ListArgs>({
      query: (params) => ({
        url: 'supplier-order-designs',
        params: params
      }),
      providesTags: (supplierOrderDesignsListData, _error, _args) =>
        getProvidedTags(
          'SupplierOrderDesigns',
          'LIST',
          supplierOrderDesignsListData?.items.map((item) => item.id)
        )
    }),
    supplierOrderDesignListBadges: builder.query<Pto.SupplierOrderDesigns.ListBadges, Pto.SupplierOrderDesigns.ListBadgeArgs>({
      query: (params) => ({
        url: 'supplier-order-designs/list-badges',
        params
      })
    }),
    updateMockupImage: builder.mutation<void, Pto.SupplierOrderDesigns.SetMockupImageUrl & { designId: string }>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/set-mockup-image-url`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (_result, _error, { designId }) => [{ type: 'SupplierOrderDesigns', id: designId }]
    }),
    updateArtworkImage: builder.mutation<void, Pto.SupplierOrderDesigns.SetArtworkImageUrls & { designId: string }>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/set-artwork-image-urls`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { designId }) => [{ type: 'SupplierOrderDesigns', id: designId }]
    }),
    addCustomizationLine: builder.mutation<void, Pto.SupplierOrderDesigns.AddCustomizationLine & { designId: string }>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/add-customization-line`,
        body,
        method: 'POST'
      }),
      invalidatesTags: (_result, _error, { designId }) => [{ type: 'SupplierOrderDesigns', id: designId }]
    }),
    removeCustomizationLine: builder.mutation<void, Pto.SupplierOrderDesigns.RemoveCustomizationLine & { designId: string }>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/remove-customization-line`,
        body,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { designId }) => [{ type: 'SupplierOrderDesigns', id: designId }]
    }),
    completeDesign: builder.mutation<void, { designId: string }>({
      query: ({ designId }) => ({
        url: `/supplier-order-designs/${designId}/complete`,
        method: 'PATCH'
      })
    }),
    holdDesign: builder.mutation<void, { designId: string } & Pto.SupplierOrderDesigns.Hold>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/hold`,
        body,
        method: 'PATCH'
      })
    }),
    changeStatus: builder.mutation<void, { designId: string } & Pto.SupplierOrderDesigns.SetStatus>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/status`,
        body,
        method: 'PATCH'
      })
    }),

    completeDesignLine: builder.mutation<void, { designId: string; designLineId: string }>({
      query: ({ designId, designLineId }) => ({
        url: `/supplier-order-designs/${designId}/design-line/${designLineId}/complete`,
        method: 'PATCH'
      })
    }),
    changeDesignLineStatus: builder.mutation<void, { designId: string; designLineId: string } & Pto.SupplierOrderDesigns.SetStatus>({
      query: ({ designId, designLineId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/design-line/${designLineId}/status`,
        body,
        method: 'PATCH'
      })
    }),
    holdDesignLine: builder.mutation<void, { designId: string; designLineId: string } & Pto.SupplierOrderDesigns.Hold>({
      query: ({ designId, designLineId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/design-line/${designLineId}/hold`,
        body,
        method: 'PATCH'
      })
    }),

    takeResponsibility: builder.mutation<string, { designId: string }>({
      query: ({ designId }) => ({
        url: `/supplier-order-designs/${designId}/responsible-users`,
        method: 'PUT'
      })
    }),
    dropResponsibility: builder.mutation<string, { designId: string }>({
      query: ({ designId }) => ({
        url: `/supplier-order-designs/${designId}/responsible-users`,
        method: 'DELETE'
      })
    }),
    addResponsible: builder.mutation<string, { designId: string; responsibleUserId: string }>({
      query: ({ designId, responsibleUserId }) => ({
        url: `/supplier-order-designs/${designId}/responsible-users/${responsibleUserId}`,
        method: 'PUT'
      })
    }),
    removeResponsible: builder.mutation<string, { designId: string; responsibleUserId: string }>({
      query: ({ designId, responsibleUserId }) => ({
        url: `/supplier-order-designs/${designId}/responsible-users/${responsibleUserId}`,
        method: 'DELETE'
      })
    }),
    updateSupplierOrderDesignComment: builder.mutation<string, { designId: string; comment: string }>({
      query: ({ designId, comment }) => ({
        url: `/supplier-order-designs/${designId}/comment`,
        body: { comment },
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Supplier order design') {
    store.dispatch(supplierOrderDesignsApi.util.invalidateTags([{ type: 'SupplierOrderDesigns', id: payload.entityId }]))
  }
}

export const subscribeToSupplierOrderDesignEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSupplierOrderDesignEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useSupplierOrderDesignListQuery,
  useSupplierOrderDesignQuery,
  useSupplierOrderDesignListBadgesQuery,
  useUpdateMockupImageMutation,
  useUpdateArtworkImageMutation,
  useAddCustomizationLineMutation,
  useRemoveCustomizationLineMutation,
  useCompleteDesignMutation,
  useCompleteDesignLineMutation,
  useChangeStatusMutation,
  useChangeDesignLineStatusMutation,
  useHoldDesignMutation,
  useHoldDesignLineMutation,
  useTakeResponsibilityMutation,
  useDropResponsibilityMutation,
  useAddResponsibleMutation,
  useRemoveResponsibleMutation,
  useUpdateSupplierOrderDesignCommentMutation
} = supplierOrderDesignsApi
