import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const customersApi = createApi({
  reducerPath: 'customersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Customers', 'CustomersReport'],
  endpoints: (builder) => ({
    customer: builder.query<Pto.Customers.Customer, string>({
      query: (customerId) => ({
        url: `/customers/${customerId}`
      }),
      providesTags: (_result, _error, customerId) => getProvidedTags('Customers', customerId)
    }),
    customerList: builder.query<Pto.Customers.List, Pto.Customers.GetCustomerListQuery>({
      query: (params) => ({
        url: '/customers',
        params
      }),
      providesTags: (customersListData, _error, _args) =>
        getProvidedTags(
          'Customers',
          'LIST',
          customersListData?.items.map((item) => item.id)
        )
    }),
    customerReport: builder.query<Pto.Customers.Report, Pto.Customers.GetCustomerReportQuery>({
      query: (params) => ({
        url: '/customers/report',
        params
      }),
      providesTags: (customersListData, _error, _args) =>
        getProvidedTags(
          'CustomersReport',
          'LIST',
          customersListData?.items.map((item) => item.id)
        )
    }),
    customerOptions: builder.query<Pto.Customers.Customer[], Pto.Customers.CustomerOptionsQuery>({
      query: ({ searchText, page, size }) => ({
        url: '/customers/options',
        params: { searchText, page, size }
      }),
      providesTags: (customerOptionsData, _error, _args) =>
        getProvidedTags(
          'Customers',
          'OPTIONS',
          customerOptionsData?.map((item) => item.id)
        )
    }),
    customerAddresses: builder.query<Pto.Addresses.ShippingAddress[], string>({
      query: (customerId) => ({
        url: `/customers/${customerId}/addresses`
      }),
      providesTags: (_result, _error, customerId) => getProvidedTags('Customers', customerId)
    }),
    registerCustomer: builder.mutation<string, Pto.Customers.RegisterCustomer>({
      query: (body) => ({
        url: '/customers/register',
        body,
        method: 'POST',
        responseHandler: 'content-type'
      })
    }),
    changeCustomerName: builder.mutation<void, Pto.Customers.ChangeName>({
      query: (body) => ({
        url: '/customers/change-name',
        body,
        method: 'PATCH'
      })
    }),
    updateCustomerShippingAddresses: builder.mutation<void, Pto.Customers.UpdateCustomerShippingAddresses>({
      query: (body) => ({
        url: '/customers/update-shipping-addresses',
        body,
        method: 'PATCH'
      })
    }),
    addCustomerShippingAddress: builder.mutation<void, Pto.Customers.AddCustomerShippingAddresses>({
      query: ({ customerId, shippingAddress }) => ({
        url: `/customers/${customerId}/addresses`,
        method: 'POST',
        body: shippingAddress
      }),
      invalidatesTags: (result, error, { customerId }) => [{ type: 'Customers', id: customerId }]
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Customer') {
    store.dispatch(customersApi.util.invalidateTags([{ type: 'Customers', id: payload.entityId }]))
  }
}

export const subscribeToCustomerEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromCustomerEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useCustomerQuery,
  useLazyCustomerQuery,
  useCustomerListQuery,
  useCustomerReportQuery,
  useCustomerOptionsQuery,
  useLazyCustomerAddressesQuery,
  useRegisterCustomerMutation,
  useChangeCustomerNameMutation,
  useUpdateCustomerShippingAddressesMutation,
  useAddCustomerShippingAddressMutation
} = customersApi
