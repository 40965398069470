import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Role, Pto } from '@merchx-v3/pto'
import { Checkbox, Form, Modal, Typography } from 'antd'

import { useUser } from 'app/auth'
import { useSetRolesMutation } from 'app/api/users-api'

import styles from './SetUserRolesModal.module.scss'

const roles = [
  { label: 'User', value: Role.User },
  { label: 'Customer', value: Role.Customer }
]

type FormType = {
  roles: Role[]
}

type Props = {
  user: Pto.Users.ListItem
  isModalOpened: boolean
  onClose: () => void
}

const SetUserRolesModal = ({ user, isModalOpened, onClose }: Props) => {
  const [form] = Form.useForm<FormType>()

  const [setUserRoles, { isLoading }] = useSetRolesMutation()

  const [currentSystemUser] = useUser()

  useEffect(() => {
    form.setFieldsValue({ roles: user.roles })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleSetRoles = ({ roles }: FormType) => {
    setUserRoles({ userId: user.id, roles })
      .unwrap()
      .then(() => {
        toast.success('User roles set successfully!')
        onClose()
      })
      .catch((error) => toast.error(error))
  }

  return (
    <Modal forceRender open={isModalOpened} onCancel={onClose} centered title="Set Roles" confirmLoading={isLoading} okText="Save" onOk={form.submit}>
      <Form name="rolesInfo" layout="vertical" form={form} onFinish={handleSetRoles} initialValues={{ roles: user.roles }}>
        <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}> User Email: {user.email}</Typography>
        <Form.Item label="Roles" name="roles">
          <Checkbox.Group
            className={styles.list}
            options={[roles, currentSystemUser?.roles.includes(Role.SystemAdmin) ? [{ label: 'System Admin', value: Role.SystemAdmin }] : []].flat()}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default SetUserRolesModal
