import { useEffect, useState } from 'react'
import { Avatar, Badge, Button, Drawer, Typography } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { ConversationList } from './ConversationList'
import { MessageList } from './MessageList'
import styles from './FloatChat.module.scss'
import { useLocation } from 'react-router-dom'
import { useConversation } from 'app/chat/context/context'
import { AddNewConversationModal } from './AddNewConversationModal'

export const FloatChat = () => {
  const location = useLocation()

  const [isAddNewChatModalVisible, setAddNewChatModalVisible] = useState<boolean>(false)

  const shouldHideFloatChatButton =
    location.pathname.includes('designers/chats') ||
    location.pathname.includes('managers/chats') ||
    location.pathname.includes('customers/conversations') ||
    !!location.pathname.match(/designers\/design-requests\/(.*)/)

  const [isFloabButtonVisible, setIsFloatButtonVisible] = useState<boolean>(true)

  const { isOnline, isConversationsListLoaded, activeConversation, isVisible, setVisible, setActiveConversation, totalUnreadMessages } = useConversation()

  useEffect(() => {
    if (shouldHideFloatChatButton && isFloabButtonVisible) {
      setIsFloatButtonVisible(false)
    } else {
      setActiveConversation('')
      setVisible(false)
      setIsFloatButtonVisible(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHideFloatChatButton])

  const handleCloseChat = () => {
    setActiveConversation('')
    setVisible(!isVisible)
  }

  if (!isFloabButtonVisible || !isOnline || !isConversationsListLoaded) return null

  return (
    <>
      <Badge count={totalUnreadMessages > 100 ? '99+' : totalUnreadMessages}>
        <Avatar
          size={40}
          style={{ backgroundColor: '#F5F7FA', border: 0 }}
          shape="circle"
          icon={<CommentOutlined style={{ color: '#00000073', cursor: 'pointer' }} />}
          onClick={handleCloseChat}
        />
      </Badge>
      <Drawer
        open={isVisible}
        width={700}
        title={
          <div className={styles.titleBox}>
            <Typography className={styles.title}>Conversations</Typography>

            <Button type="primary" onClick={() => setAddNewChatModalVisible(true)}>
              New
            </Button>
          </div>
        }
        styles={{ body: { padding: '0 0 8px' } }}
        onClose={() => setVisible(false)}
      >
        <ConversationList height="100%" />
        <Drawer
          title={false}
          width={700}
          closable={false}
          onClose={() => setActiveConversation('')}
          open={isVisible && !!activeConversation}
          styles={{ body: { padding: '0 0 8px ' } }}
        >
          <MessageList />
        </Drawer>
      </Drawer>

      <AddNewConversationModal isOpen={isAddNewChatModalVisible} onClose={() => setAddNewChatModalVisible(false)} />
    </>
  )
}
