import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type ListArgs = Pto.CustomGatewayCategories.CustomGatewayCategoryListQuery & {
  customGatewaySettingsId: string
}

type SettingsArgs = {
  customGatewaySettingsId: string
}

export const customGatewayCategoriesApi = createApi({
  reducerPath: 'customGatewayCategoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['CustomGatewayCategories'],
  endpoints: (builder) => ({
    getCustomGatewayCategoryList: builder.query<Pto.CustomGatewayCategories.List, ListArgs>({
      query: (args) => {
        const { customGatewaySettingsId, ...query } = args
        return {
          url: `/custom-gateway-categories/${customGatewaySettingsId}/list`,
          params: query
        }
      },
      providesTags: (gatewayListData, _error, { customGatewaySettingsId }) =>
        getProvidedTags(
          'CustomGatewayCategories',
          `${customGatewaySettingsId}-LIST`,
          gatewayListData?.items.map((item) => item.id)
        )
    }),

    customGatewayCategoryOptions: builder.query<Pto.Option[], SettingsArgs & Pto.CustomGatewayCategories.OptionArgs>({
      query: ({ customGatewaySettingsId, searchText, requiredItemId }) => ({
        url: `/custom-gateway-categories/${customGatewaySettingsId}/options`,
        method: 'GET',
        params: { searchText, requiredItemId }
      }),
      providesTags: (_result, _error, { customGatewaySettingsId }) => getProvidedTags('CustomGatewayCategories', `${customGatewaySettingsId}-OPTIONS`)
    }),

    getCustomGatewayCategory: builder.query<Pto.CustomGatewayCategories.CustomGatewayCategory, SettingsArgs & { customGatewayCategoryId: string }>({
      query: ({ customGatewaySettingsId, customGatewayCategoryId }) => ({
        url: `custom-gateway-categories/${customGatewaySettingsId}/${customGatewayCategoryId}`
      })
    }),

    createCategory: builder.mutation<void, Pto.CustomGatewayCategories.CreateCustomGatewayCategory & SettingsArgs>({
      query: (createGatewayDto) => {
        const { customGatewaySettingsId, ...body } = createGatewayDto
        return {
          url: `/custom-gateway-categories/${customGatewaySettingsId}`,
          method: 'POST',
          body: body
        }
      }
    }),

    changeCategoryName: builder.mutation<void, SettingsArgs & { customGatewayCategoryId: string; name: string }>({
      query: ({ customGatewaySettingsId, customGatewayCategoryId, name }) => ({
        url: `/custom-gateway-categories/${customGatewaySettingsId}/${customGatewayCategoryId}/name`,
        method: 'PATCH',
        body: { name }
      })
    }),

    changeCategoryExternalId: builder.mutation<void, SettingsArgs & { customGatewayCategoryId: string; externalId: string }>({
      query: ({ customGatewaySettingsId, customGatewayCategoryId, externalId }) => ({
        url: `/custom-gateway-categories/${customGatewaySettingsId}/${customGatewayCategoryId}/external-id`,
        method: 'PATCH',
        body: { externalId }
      })
    }),

    removeCustomGatewayCategory: builder.mutation<void, SettingsArgs & { customGatewayCategoryId: string }>({
      query: ({ customGatewaySettingsId, customGatewayCategoryId }) => ({
        url: `/custom-gateway-categories/${customGatewaySettingsId}/${customGatewayCategoryId}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useCreateCategoryMutation,
  useChangeCategoryNameMutation,
  useGetCustomGatewayCategoryQuery,
  useChangeCategoryExternalIdMutation,
  useGetCustomGatewayCategoryListQuery,
  useCustomGatewayCategoryOptionsQuery,
  useRemoveCustomGatewayCategoryMutation
} = customGatewayCategoriesApi
