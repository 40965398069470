import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type SupplierId = string

export const checkoutServicesApi = createApi({
  reducerPath: 'checkoutServicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['CheckoutServices'],
  endpoints: (builder) => ({
    checkoutServices: builder.query<Pto.CheckoutServices.CheckoutService[], SupplierId | undefined>({
      query: (supplierId) => ({
        url: `/checkout-services/${supplierId}`
      }),
      providesTags: (checkoutServicesData, _error, _args) =>
        getProvidedTags(
          'CheckoutServices',
          'LIST',
          checkoutServicesData?.map((service) => service.serviceType)
        )
    }),
    checkoutServiceMetaKeys: builder.query<Pto.MetaKey[], { supplierId: SupplierId, serviceType: Pto.CheckoutServices.ServiceType, optionType: Pto.CheckoutServices.OptionType }>({
      query: ({ supplierId, serviceType, optionType }) => ({
        url: `/checkout-services/${supplierId}/${serviceType}/${optionType}/meta-keys`
      })
    }),
    createServiceOption: builder.mutation<string, Pto.CheckoutServices.CreateServiceOption & { supplierId: SupplierId }>({
      query: ({ supplierId, serviceType, type, name, meta }) => ({
        url: `/checkout-services/${supplierId}/options`,
        method: 'POST',
        body: {
          serviceType,
          name,
          type,
          meta
        },
      }),
      invalidatesTags: getProvidedTags('CheckoutServices', 'LIST')
    }),
    changeServiceOptionMetaValue: builder.mutation<string, Pto.CheckoutServices.ChangeServiceOptionMetaValue & { supplierId: SupplierId }>({
      query: ({ supplierId, serviceType, optionName, key, value }) => ({
        url: `/checkout-services/${supplierId}/options/meta-value`,
        method: 'PATCH',
        body: {
          serviceType,
          optionName,
          key,
          value,
        },
      }),
      invalidatesTags: (result, error, { serviceType }) =>
        getProvidedTags('CheckoutServices', `${serviceType}`)
    }),
    changeServiceOptionName: builder.mutation<string, Pto.CheckoutServices.ChangeServiceOptionName & { supplierId: SupplierId }>({
      query: ({ supplierId, serviceType, sourceOptionName, newOptionName }) => ({
        url: `/checkout-services/${supplierId}/options/name`,
        method: 'PATCH',
        body: {
          serviceType,
          sourceOptionName,
          newOptionName,
        },
      }),
      invalidatesTags: (result, error, { serviceType }) =>
        getProvidedTags('CheckoutServices', `${serviceType}`)
    }),
    changeServiceOptionActivity: builder.mutation<string, Pto.CheckoutServices.ChangeServiceOptionActivity & { supplierId: SupplierId }>({
      query: ({ supplierId, serviceType, optionName, isActive }) => ({
        url: `/checkout-services/${supplierId}/options/is-active`,
        method: 'PATCH',
        body: {
          serviceType,
          optionName,
          isActive,
        },
      }),
      invalidatesTags: (result, error, { serviceType }) =>
        getProvidedTags('CheckoutServices', `${serviceType}`)
    }),
    deleteServiceOption: builder.mutation<string, Pto.CheckoutServices.RemoveServiceOption & { supplierId: SupplierId }>({
      query: ({ supplierId, serviceType, optionName }) => ({
        url: `/checkout-services/${supplierId}/options`,
        method: 'DELETE',
        body: {
          serviceType,
          optionName,
        },
      }),
      invalidatesTags: (result, error, { serviceType }) =>
        getProvidedTags('CheckoutServices', `${serviceType}`)
    }),
  })
})

export const { 
  useCheckoutServicesQuery, 
  useCheckoutServiceMetaKeysQuery, 
  useCreateServiceOptionMutation,
  useChangeServiceOptionMetaValueMutation,
  useChangeServiceOptionNameMutation,
  useChangeServiceOptionActivityMutation,
  useDeleteServiceOptionMutation
} = checkoutServicesApi
