import { BaseQueryFn, FetchBaseQueryError, FetchBaseQueryMeta, MutationDefinition } from '@reduxjs/toolkit/dist/query'
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate'
import { toast } from 'react-toastify'

type MutationFn<T> = () => MutationActionCreatorResult<MutationDefinition<any, BaseQueryFn<any, any, FetchBaseQueryError, {}, FetchBaseQueryMeta>, any, T, any>>
type MutationResult<T> = { isSuccessful: boolean; data?: T }

export const runMutation = async <T>(mutationFn: MutationFn<T>, successMessage: string): Promise<MutationResult<T>> => {
  const mutationResult: MutationResult<T> = {
    isSuccessful: false
  }

  await mutationFn()
    .unwrap()
    .then((data) => {
      toast.success(successMessage)

      mutationResult.isSuccessful = true
      mutationResult.data = data
    })
    .catch((error) => {
      if ('data' in error) {
        toast.error(error.data.error)
      } else {
        toast.error(error)
      }
    })

  return mutationResult
}
