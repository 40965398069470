import { useState } from 'react'
import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { Pto } from '@merchx-v3/pto'
import useDebounce from 'helpers/useDebounce'
import { useCustomerOptionsQuery } from 'app/api/customers-api'

type Props = {
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: Pto.Option
  size?: SizeType

  onChange?: (value?: Pto.Option) => void
}

const CustomerOptions = (props: Props) => {
  const { placeholder, disabled, className, value: selectedCustomer, size, onChange } = props

  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: customerOptions } = useCustomerOptionsQuery({ searchText: debouncedSearchText })

  const handleSelect = (_value: string, option: Pto.Option) => {
    onChange && onChange(option)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <Select
      options={customerOptions?.map((customer) => ({ value: customer.id, label: `${customer.firstName} ${customer.lastName} (${customer.email})` }))}
      className={className}
      value={selectedCustomer?.value}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      allowClear
      onClear={handleClear}
      optionFilterProp="label"
    />
  )
}

export default CustomerOptions
