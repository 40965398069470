import { useEffect, useState } from 'react'
import { Image, Modal, Upload } from 'antd'
import type { RcFile } from 'antd/es/upload'
import { PlusOutlined } from '@ant-design/icons'
import type { UploadFile } from 'antd/es/upload/interface'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { sha1 } from 'object-hash'
import { toast } from 'react-toastify'
import { Pto } from '@merchx-v3/pto'
import { FilesAPI } from '@merchx-v3/shared-functions'

import { FileUploader } from './FileUploader'
import DraggableUploadListItem from './DraggableUploadListItem'

import styles from './UploadFilesV2.module.scss'

type Props = {
  owner: Pto.Files.Owner // Кто будет владеть файлом
  ownerId?: string // Айди владельца
  type: Pto.Files.Type // Тип файла, Артворк, картинка и тд, нужно для определения лайфсайкла
  meta?: Pto.Meta[] // Метаданные которые нужно добавить к файлу, может вариант кей продукта и любые рандомные значения

  disabled?: boolean
  className?: string
  maxAllowedFilesLength?: number
  isRemoveConfirm?: boolean

  value?: UploadFile<RcFile>[]
  needMainBadge?: boolean
  onChange?: (value: UploadFile<RcFile>[]) => void
}

const UploadFilesV2 = (props: Props) => {
  const { owner, ownerId, type, meta = [], className, disabled, value = [], isRemoveConfirm = false, onChange, maxAllowedFilesLength, needMainBadge = false } = props

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState<UploadFile<RcFile>[]>([])

  const uploadLimits = FilesAPI.getUploadLimits(type)
  const maxFilesAllowed = maxAllowedFilesLength || uploadLimits.maxFiles

  const [filesToUpload, setFilesToUpload] = useState<RcFile[]>([])

  useEffect(() => {
    setFileList(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sha1(value)])

  const addFileToUpload = async (upload: { file: RcFile }) => {
    setFilesToUpload((prev) => [...prev, upload.file])
  }

  // Этот метод вызывается для каждого выбранного файла без изменения пропсов объекта, поэтому
  // посчитаем количество выгруженных файлов локально для каждого вызова метода
  let numberOfAddedFiles = 0
  const isFilesValid = (file: RcFile) => {
    if (fileList.length + filesToUpload.length + numberOfAddedFiles >= maxFilesAllowed) {
      toast.error('Max files number exceeded')
      return false
    }

    const nameParts = file.name.split('.')
    const extension = nameParts[nameParts?.length - 1] || ''

    if (!uploadLimits.mimetypes.includes(file.type) && !uploadLimits.mimetypes.includes(`.${extension}`)) {
      toast.error('This file type not supported')
      return false
    }

    if (file.size > uploadLimits.maxSize) {
      toast.error(`File size exceed. Limit is ${uploadLimits.maxSizeDisplayName}`)
      return false
    }

    numberOfAddedFiles++
    return true
  }

  const handleDragFinished = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = fileList.findIndex((i) => i.uid === active.id)
      const overIndex = fileList.findIndex((i) => i.uid === over?.id)

      const updatedFileList = arrayMove(fileList, activeIndex, overIndex)
      setFileList(updatedFileList)
      onChange && onChange(updatedFileList)
    }
  }

  const openPreview = async (file: UploadFile) => {
    const isArgsPresent = file.url?.lastIndexOf('?') !== -1
    let originalFileUrl = file.url
    if (isArgsPresent) {
      originalFileUrl = file.url?.substring(0, file.url.lastIndexOf('?'))
    }

    if (!originalFileUrl) return

    setPreviewImage(`/images/${originalFileUrl}`)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    setPreviewOpen(true)
  }

  const closePreview = () => {
    setPreviewOpen(false)
    setPreviewImage('')
    setPreviewTitle('')
  }

  const confirmRemove = () => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: 'Are you sure you want to delete this file?',
        content: 'The action is irreversible.',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          resolve(true)
        },
        onCancel() {
          resolve(false)
        }
      })
    })
  }

  const downloadFile = (file: UploadFile) => {
    const downloadUrl = `/images/${file.url}`
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const removeFile = async (file: UploadFile<RcFile>) => {
    if (isRemoveConfirm) {
      const isConfirmed = await confirmRemove()
      if (!isConfirmed) return
    }

    const fileToRemove = fileList.find((item) => item.uid === file.uid)
    if (fileToRemove) {
      const newListOfFilesToUpload = fileList.filter((item) => item.uid !== file.uid)
      setFileList(newListOfFilesToUpload)
      onChange && onChange(newListOfFilesToUpload)
    }
  }

  const handleFilesUploaded = (uploadedFiles: UploadFile<RcFile>[]) => {
    const updatedFileList = [...fileList, ...uploadedFiles.filter((uploadedFile) => !fileList.some((existFile) => existFile.url === uploadedFile.url))]
    setFileList(updatedFileList)
    setFilesToUpload([])
    onChange && onChange(updatedFileList)
  }

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 }
  })

  const uploadButton = (
    <div className={styles.uploadBtn}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <>
      <DndContext sensors={[sensor]} onDragEnd={handleDragFinished}>
        <SortableContext items={fileList.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
          <Upload
            rootClassName={className}
            accept={uploadLimits.mimetypes.length ? uploadLimits.mimetypes.join(', ') : undefined}
            listType="picture-card"
            disabled={disabled}
            fileList={fileList.map((item) => ({ ...item, status: 'done' }))}
            beforeUpload={isFilesValid}
            onDownload={downloadFile}
            onRemove={removeFile}
            // @ts-expect-error It using wider type than we need
            customRequest={addFileToUpload}
            showUploadList={{ showDownloadIcon: true, showPreviewIcon: true, showRemoveIcon: true }}
            onPreview={openPreview}
            className={`${styles.box} ${needMainBadge ? styles.badge : ''}`}
            multiple
            directory={false}
            maxCount={maxFilesAllowed}
            itemRender={(originNode, file) => <DraggableUploadListItem originNode={originNode} file={file} />}
          >
            {fileList.length >= maxFilesAllowed ? null : uploadButton}
          </Upload>
        </SortableContext>
      </DndContext>
      <FileUploader owner={owner} ownerId={ownerId} type={type} meta={meta} filesToUpload={filesToUpload} onFilesUploaded={handleFilesUploaded} />
      {previewOpen && (
        <Modal open title={previewTitle} footer={null} onCancel={closePreview}>
          <div className={styles.previewContainer}>
            <Image width="100%" alt="example" style={{ width: '100%' }} src={previewImage} />
          </div>
        </Modal>
      )}
    </>
  )
}

export default UploadFilesV2
