import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const conversationsApi = createApi({
  reducerPath: 'conversationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Conversations'],
  endpoints: (builder) => ({
    conversationsUsers: builder.query<Pto.Conversations.User[], Pto.Conversations.GetUsersQuery>({
      query: ({ searchText, size }) => ({
        url: '/conversations/users',
        params: { searchText, size }
      }),
      providesTags: (conversationsUsersData) =>
        getProvidedTags(
          'Conversations',
          'LIST',
          conversationsUsersData?.map((item) => item.id)
        )
    }),
    designRequestConversationId: builder.query<string | undefined, string>({
      query: (designRequestId: string) => ({
        url: `/conversations/design-request/${designRequestId}`,
        method: 'GET',
        responseHandler: 'text'
      }),
      providesTags: (_result, _error, designRequestId) => getProvidedTags('Conversations', designRequestId)
    }),
    supplierOrderDesignConversationId: builder.query<string | undefined, string>({
      query: (supplierOrderDesignLineId: string) => ({
        url: `/conversations/supplier-order-design/${supplierOrderDesignLineId}`,
        method: 'GET',
        responseHandler: 'text'
      }),
      providesTags: (_result, _error, supplierOrderDesignLineId) => getProvidedTags('Conversations', supplierOrderDesignLineId)
    }),
    supplierOrderDesignConversationIdManagerToDesigner: builder.query<string | undefined, string>({
      query: (supplierOrderDesignId: string) => ({
        url: `/conversations/supplier-order-design/${supplierOrderDesignId}/manager-to-designer`,
        method: 'GET',
        responseHandler: 'text'
      })
    }),
    supplierOrderDesignConversationIdManagerToCustomer: builder.query<string | undefined, string>({
      query: (supplierOrderDesignId: string) => ({
        url: `/conversations/supplier-order-design/${supplierOrderDesignId}/manager-to-customer`,
        method: 'GET',
        responseHandler: 'text'
      })
    }),

    joinConversation: builder.mutation<void, string>({
      query: (conversationId) => ({
        url: `/conversations/${conversationId}/join`,
        method: 'PATCH',
        responseHandler: 'content-type'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Conversation') {
    store.dispatch(conversationsApi.util.invalidateTags([{ type: 'Conversations', id: payload.entityId }]))
  }
}

export const subscribeToConversationEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromConversationEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useConversationsUsersQuery,
  useDesignRequestConversationIdQuery,
  useSupplierOrderDesignConversationIdQuery,
  useJoinConversationMutation,
  useSupplierOrderDesignConversationIdManagerToCustomerQuery,
  useSupplierOrderDesignConversationIdManagerToDesignerQuery
} = conversationsApi
