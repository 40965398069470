import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type TagArgs = {
  sourceId: string
  sourceType: Pto.Tags.SourceType
}

export const tagsApi = createApi({
  reducerPath: 'tagApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Tags'],
  endpoints: (builder) => ({
    tags: builder.query<string[], TagArgs>({
      query: ({ sourceId, sourceType }) => ({
        url: `/tags/${sourceType}/${sourceId}`
      }),
      providesTags: (_result, _error, { sourceId, sourceType }) => getProvidedTags('Tags', `${sourceType}_${sourceId}`)
    }),
    addTag: builder.mutation<void, Pto.Tags.AddTag & TagArgs>({
      query: ({ sourceId, sourceType, ...body }) => ({
        url: `tags/${sourceType}/${sourceId}`,
        body,
        method: 'POST'
      })
    }),
    removeTag: builder.mutation<void, Pto.Tags.RemoveTag & TagArgs>({
      query: ({ sourceId, sourceType, ...body }) => ({
        url: `tags/${sourceType}/${sourceId}`,
        body,
        method: 'DELETE'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Supplier order fulfillment') {
    store.dispatch(tagsApi.util.invalidateTags(getProvidedTags('Tags', `${Pto.Tags.SourceType.SupplierOrderFulfillment}_${payload.entityId}`)))
  }

  if (payload.entityType === 'Supplier order') {
    store.dispatch(tagsApi.util.invalidateTags(getProvidedTags('Tags', `${Pto.Tags.SourceType.SupplierOrderFulfillment}_${payload.entityId}`)))
    store.dispatch(tagsApi.util.invalidateTags(getProvidedTags('Tags', `${Pto.Tags.SourceType.SupplierOrder}_${payload.entityId}`)))
    store.dispatch(tagsApi.util.invalidateTags(getProvidedTags('Tags', `${Pto.Tags.SourceType.SupplierOrderDesign}_${payload.entityId}`)))
    store.dispatch(tagsApi.util.invalidateTags(getProvidedTags('Tags', `${Pto.Tags.SourceType.SupplierOrderShipping}_${payload.entityId}`)))
  }

  if (payload.entityType === 'Supplier order design') {
    store.dispatch(tagsApi.util.invalidateTags(getProvidedTags('Tags', `${Pto.Tags.SourceType.SupplierOrderDesign}_${payload.entityId}`)))
  }

  if (payload.entityType === 'Supplier order shipping') {
    store.dispatch(tagsApi.util.invalidateTags(getProvidedTags('Tags', `${Pto.Tags.SourceType.SupplierOrderShipping}_${payload.entityId}`)))
  }

  if (payload.entityType === 'Supplier order fulfillment task') {
    store.dispatch(tagsApi.util.invalidateTags(getProvidedTags('Tags', `${Pto.Tags.SourceType.SupplierOrderFulfillmentTask}_${payload.entityId}`)))
  }
}

export const subscribeToTagEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromTagEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const { useTagsQuery, useAddTagMutation, useRemoveTagMutation } = tagsApi
