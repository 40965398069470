import { useState } from 'react'
import { Pto } from '@merchx-v3/pto'
import { Select } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import useDebounce from 'helpers/useDebounce'
import { useProcessingTemplateOptionsQuery } from 'app/api/processing-templates-api'

type Props = {
  supplierId: string
  defaultValue?: string
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: Pto.Option
  size?: SizeType

  onChange?: (value?: Pto.Option) => void
}

const ProcessingTemplateOptions = (props: Props) => {
  const { supplierId, placeholder, disabled, className, value: selectedDesign, size, onChange } = props

  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: processingTemplateOptions = [] } = useProcessingTemplateOptionsQuery({ supplierId, searchText: debouncedSearchText }, { skip: !supplierId })

  const handleSelect = (_value: string, option: Pto.Option) => {
    onChange && onChange(option)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <Select
      options={processingTemplateOptions}
      className={className}
      value={selectedDesign?.value}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      allowClear
      showSearch
      onClear={handleClear}
      labelInValue
      optionFilterProp="label"
    />
  )
}

export default ProcessingTemplateOptions
