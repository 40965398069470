import { sha1 } from 'object-hash'
import { Pto } from '@merchx-v3/pto'
import { useThrottle } from 'helpers'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { RcFile, UploadFile } from 'antd/es/upload'
import { Progress, Spin, Modal, Typography } from 'antd'
import { useUploadTemporaryFilesMutation } from 'shared/files-api'

import styles from './FileUploader.module.scss'

const spinIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />

type Props = {
  owner: Pto.Files.Owner
  ownerId?: string
  type: Pto.Files.Type

  filesToUpload: RcFile[]

  meta: Pto.Meta[]

  onCancel?: () => void
  onFilesUploaded: (uploadedFiles: UploadFile<RcFile>[]) => void
}

export const FileUploader = (props: Props) => {
  const { owner, ownerId, type, filesToUpload, meta, onFilesUploaded, onCancel } = props

  const [uploadRequest, setUploadRequest] = useState<any>() // Ссылка на запрос мутации, понадобится в случае если мы хотим отменить запрос на выгрузку
  const [progress, setProgress] = useState<number>(0)
  const [message, setMessage] = useState<string>('')

  const throttledFilesList = useThrottle(filesToUpload)

  const [uploadFiles, { isLoading: isUploadInProgress }] = useUploadTemporaryFilesMutation()

  useEffect(() => {
    // Если есть файлы на выгрузку, то запускаем их обработку
    if (throttledFilesList.length) {
      setUploadRequest(undefined)
      setMessage('')
      setProgress(0)
      void uploadFilesToServer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sha1(throttledFilesList)])

  const handleUploadProgressChanged = (loaded: number, progress: number) => {
    setProgress(progress)

    if (progress === 100) {
      setMessage('Generating public URL')
    } else {
      // Найдем файл который выгружается в данный момент на сервер
      const activeFile = filesToUpload.reduce(
        (accum, file, index) => {
          if (loaded > accum.totalSize) {
            accum.filename = file.name
            accum.totalSize += file.size
            accum.fileNumber = index + 1
          }
          return accum
        },
        { totalSize: 0, filename: '', fileNumber: 0 }
      )

      setMessage(`Uploading "${activeFile.filename}" to server ${activeFile.fileNumber}/${filesToUpload.length}`)
    }
  }

  const uploadFilesToServer = async () => {
    if (!ownerId) return

    const request = uploadFiles({
      owner,
      ownerId,
      type,
      uploads: filesToUpload.map((file) => ({ id: file.uid, displayName: file.name, meta })),
      files: filesToUpload,
      onUploadProgress: handleUploadProgressChanged
    })

    setUploadRequest(request)

    await request
      .unwrap()
      .then((uploadedFiles) => {
        const listOfUploadedFiles: UploadFile<RcFile>[] = []

        for (const upload of uploadedFiles) {
          const uploadedFile: UploadFile<RcFile> = {
            uid: upload.id,
            name: upload.displayName,
            status: 'done',
            url: upload.fileUrl,
            originFileObj: filesToUpload.find((item) => item.uid === upload.id),
            thumbUrl: `/images/${upload.fileUrl}?width=200&height=200`
          }

          listOfUploadedFiles.push(uploadedFile)
        }

        toast.success('File(s) uploaded to server')
        onFilesUploaded(listOfUploadedFiles)
      })
      .catch((error) => {
        toast.error(error)
      })
  }

  const handleCancelUpload = async () => {
    if (onCancel) {
      await uploadRequest?.abort()
      onCancel()
    }
  }

  return (
    <Modal open={isUploadInProgress} footer={false} onCancel={handleCancelUpload} centered title="Uploading file(s) to server">
      <Progress percent={progress || 0} status={progress !== 100 ? 'active' : 'success'} strokeColor={{ from: '#108ee9', to: '#87d068' }} />
      <div className={styles.textContainer}>
        {progress === 100 && <Spin indicator={spinIcon} />}
        <Typography.Text type="secondary">{message}</Typography.Text>
      </div>
    </Modal>
  )
}
