import React, { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Layout as AntdLayout } from 'antd'

import { PortalContext } from 'components/App/App'
import { Loading } from 'components/Loading'
import { SupplierContextProvider } from 'components/SupplierContext/provider'
import SiderMenu from '../SiderMenu'
import Header from './Header'
import { useUser } from 'app/auth'

import styles from './Layout.module.scss'

type LayoutProps = {
  shouldWaitUser?: boolean
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ children, shouldWaitUser = false }) => {
  const [user] = useUser()

  const { setPortal } = useContext(PortalContext)

  useEffect(() => {
    setPortal('/common')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (shouldWaitUser && !user) return <Loading />

  const supplierId = localStorage.getItem('supplierId')
  if (!supplierId) return <Navigate to={'/'} />

  const supplierCenterId = localStorage.getItem('supplierCenterId')
  if (!supplierCenterId) return <Navigate to={'/'} />

  return (
    <SupplierContextProvider supplierId={supplierId} supplierCenterId={supplierCenterId}>
      <CommonLayout>{children}</CommonLayout>
    </SupplierContextProvider>
  )
}

const CommonLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [collapsedMainMenu, setCollapsedMainMenu] = useState(false)

  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <AntdLayout.Header className={styles.header}>
        <Header />
      </AntdLayout.Header>
      <AntdLayout>
        <AntdLayout.Sider collapsible={true} collapsed={collapsedMainMenu} onCollapse={setCollapsedMainMenu} width={242} theme="light">
          <SiderMenu collapsed={collapsedMainMenu} />
        </AntdLayout.Sider>
        <AntdLayout.Content>{children}</AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  )
}

export default Layout
