import { useState } from 'react'
import { Button, Select, Typography } from 'antd'
import { Pto, Role } from '@merchx-v3/pto'

import { useUser } from 'app/auth'
import { useSupplierCentersBySupplierQuery } from 'app/api/suppliers-api'

import styles from './IndexPage.module.scss'

const defaultOption: Pto.Option = { value: '', label: 'Not selected' }
const noSupplierCentersOption: Pto.Option = { value: '', label: 'No supplier centers registered' }

const IndexPage = () => {
  const [user] = useUser()
  const isAdmin = user?.roles?.includes(Role.SystemAdmin)

  const [supplierId, setSupplierId] = useState(localStorage.getItem('supplierId') || '')
  const [supplierCenterId, setSupplierCenterId] = useState(localStorage.getItem('supplierCenterId') || '')

  const userSuppliersList: Pto.Option[] = [defaultOption, { value: '28840339-660d-4a0b-b804-cf8f99e94d90', label: 'Merchconnect' }]

  const { data: supplierCenters = [] } = useSupplierCentersBySupplierQuery(supplierId, { skip: supplierId === '' })

  const supplierCenterOptions: Pto.Option[] = supplierCenters.length ? [] : [noSupplierCentersOption]

  for (const supplierCenter of supplierCenters) {
    supplierCenterOptions.push({ value: supplierCenter.id, label: supplierCenter.name })
  }

  const handleSupplierSelected = (supplierId: string) => {
    if (supplierId) {
      localStorage.setItem('supplierId', supplierId)
      setSupplierId(supplierId)
    }
  }

  const handleSupplierCenterSelected = (supplierCenterId: string) => {
    if (supplierId) {
      localStorage.setItem('supplierCenterId', supplierCenterId)
      setSupplierCenterId(supplierCenterId)
    }
  }

  return (
    <div className={styles.container}>
      <img src="/logoBlack.svg" alt="Merchconnect" className={styles.logo} />
      <div className={styles.supplierInfoForm}>
        <div className={styles.supplierInfoItem}>
          <Typography>Supplier:</Typography>
          <Select style={{ minWidth: '300px' }} value={supplierId} options={userSuppliersList} onChange={handleSupplierSelected}></Select>
        </div>
        {supplierId && (
          <div className={styles.supplierInfoItem}>
            <Typography>Supplier center:</Typography>
            <Select style={{ minWidth: '300px' }} value={supplierCenterId} options={supplierCenterOptions} onChange={handleSupplierCenterSelected}></Select>
          </div>
        )}
      </div>
      <div className={styles.interfacesBox}>
        {isAdmin && (
          <div className={styles.interface}>
            <Typography>Use Admin panel</Typography>
            <Button disabled={!supplierId || !supplierCenterId} href="/admin">
              Admin
            </Button>
          </div>
        )}

        <div className={styles.interface}>
          <Typography>Use Common panel</Typography>
          <Button disabled={!supplierId || !supplierCenterId} href="/common">
            Common
          </Button>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
