import { AutoComplete } from 'antd'
import { Pto } from '@merchx-v3/pto'
import { BaseSelectRef } from 'rc-select'
import { Space, Tag, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { runMutation } from 'shared/run-mutation'
import { useAddTagMutation, useRemoveTagMutation, useTagsQuery } from 'app/api/tags-api'
import styles from './EditableTags.module.scss'

type PropsType = {
  sourceId: string
  sourceType: Pto.Tags.SourceType
  disabled?: boolean
}

const EditableTags = (props: PropsType) => {
  const { disabled, sourceId, sourceType } = props

  const { data: tags = [], isLoading } = useTagsQuery({ sourceId, sourceType })
  const [addTag, { isLoading: isAddingTag }] = useAddTagMutation()
  const [removeTag, { isLoading: isRemovingTag }] = useRemoveTagMutation()

  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<BaseSelectRef>(null)

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  const handleAddTag = async (name: string) => {
    const { isSuccessful } = await runMutation(() => addTag({ sourceId, sourceType, name }), 'Tag added')

    return isSuccessful
  }

  const handleClose = async (name: string) => {
    const { isSuccessful } = await runMutation(() => removeTag({ sourceId, sourceType, name }), 'Tag removed')

    return isSuccessful
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (value: string) => {
    setInputValue(value)
  }

  const handleInputConfirm: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key !== 'Enter') return

    if (inputValue && tags.indexOf(inputValue) === -1) {
      handleAddTag(inputValue)
    }
    setInputVisible(false)
    setInputValue('')
  }

  const handleInputBlur = () => {
    setInputVisible(false)
    setInputValue('')
  }

  const handleInputSelected = (value: string) => {
    if (tags.indexOf(value) === -1) {
      handleAddTag(value)
    }
    setInputVisible(false)
    setInputValue('')
  }

  const tagInputStyle: React.CSSProperties = {
    width: 180,
    verticalAlign: 'top',
    height: 22
  }

  const tagPlusStyle: React.CSSProperties = {
    borderStyle: 'dashed'
  }

  const shouldDisable = disabled || isLoading || isAddingTag || isRemovingTag

  return (
    <Space size={[0, 8]} wrap className={styles.container}>
      <Space size={[0, 8]} wrap>
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20
          const tagElem = (
            <Tag key={tag} closable style={{ userSelect: 'none' }} onClose={() => handleClose(tag)}>
              <span>{isLongTag ? `${tag.slice(0, 20)}...` : tag}</span>
            </Tag>
          )
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          )
        })}
      </Space>
      {inputVisible ? (
        <AutoComplete
          ref={inputRef}
          size="small"
          style={tagInputStyle}
          value={inputValue}
          disabled={shouldDisable}
          onSelect={handleInputSelected}
          onKeyUp={handleInputConfirm}
          onSearch={handleInputChange}
          onBlur={handleInputBlur}
        />
      ) : (
        <Tag style={tagPlusStyle} onClick={showInput}>
          <PlusOutlined /> New Tag
        </Tag>
      )}
    </Space>
  )
}

export default EditableTags
