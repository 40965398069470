import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const designTemplatesApi = createApi({
  reducerPath: 'designTemplatesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['DesignTemplates'],
  endpoints: (builder) => ({
    designTemplate: builder.query<Pto.DesignTemplates.DesignTemplate, string>({
      query: (templateId) => `/design-templates/${templateId}`,
      providesTags: (_result, _error, templateId) => getProvidedTags('DesignTemplates', templateId)
    }),
    designTemplateList: builder.query<Pto.DesignTemplates.List, Pto.DesignTemplates.DesignTemplateListQuery>({
      query: ({ supplierId, page, size }) => ({
        url: 'design-templates',
        params: { supplierId, page, size }
      }),
      providesTags: (designTemplateData, _error, _args) =>
        getProvidedTags(
          'DesignTemplates',
          'LIST',
          designTemplateData?.items.map((item) => item.id)
        )
    }),
    designTemplateOptions: builder.query<Pto.Option[], Pto.DesignTemplates.DesignTemplateOptionsQuery>({
      query: ({ supplierId, searchText, page = 1, size }) => ({
        url: 'design-templates/options',
        params: { supplierId, searchText, page, size }
      }),
      providesTags: (designTemplatesOptionsData, _error, _args) =>
        getProvidedTags(
          'DesignTemplates',
          'OPTIONS',
          designTemplatesOptionsData?.map((item) => item.value)
        )
    }),
    designTemplatePrintAreaOptions: builder.query<Pto.Option[], Pto.DesignTemplates.DesignTemplatePrintAreaOptionsQuery>({
      query: ({ supplierId, searchText }) => ({
        url: 'design-templates/print-areas/options',
        params: { supplierId, searchText }
      }),
      providesTags: (designTemplatesOptionsData, _error, _args) =>
        getProvidedTags(
          'DesignTemplates',
          'OPTIONS',
          designTemplatesOptionsData?.map((item) => item.value)
        )
    }),
    createDesignTemplate: builder.mutation<string, Pto.DesignTemplates.CreateDesignTemplate>({
      query: (createTemplateDto) => ({
        url: 'design-templates',
        body: createTemplateDto,
        method: 'POST',
        responseHandler: (response) => response.text()
      })
    }),
    updateDesignTemplate: builder.mutation<string, Pto.DesignTemplates.UpdateDesignTemplate>({
      query: (updateTemplateDto) => ({
        url: '/design-templates',
        body: updateTemplateDto,
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Design template') {
    store.dispatch(designTemplatesApi.util.invalidateTags([{ type: 'DesignTemplates', id: payload.entityId }]))
  }
}

export const subscribeToDesignTemplateEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromDesignTemplateEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useDesignTemplateQuery,
  useDesignTemplateOptionsQuery,
  useDesignTemplateListQuery,
  useCreateDesignTemplateMutation,
  useUpdateDesignTemplateMutation,
  useDesignTemplatePrintAreaOptionsQuery
} = designTemplatesApi
