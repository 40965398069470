import { Pto } from '@merchx-v3/pto'
import { settings } from 'config/settings'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'
import { store } from 'app/store'

import { customGatewayProductsApi } from './custom-gateway-products-api'
import { customGatewayCategoriesApi } from './custom-gateway-categories-api'
import { customGatewayBlankProductsApi } from './custom-gateway-blank-products-api'

const { protocol, domain } = settings.site

export const customGatewaySettingsApi = createApi({
  reducerPath: 'customGatewaySettingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['CustomGatewaySettings'],
  endpoints: (builder) => ({
    customGatewaySettingList: builder.query<Pto.CustomGatewaySettings.List, Pto.CustomGatewaySettings.CustomGatewaySettingListQuery>({
      query: (params) => ({
        url: 'custom-gateway-settings',
        params
      }),
      providesTags: (gatewayListData, _error, _args) =>
        getProvidedTags(
          'CustomGatewaySettings',
          'LIST',
          gatewayListData?.items.map((item) => item.id)
        )
    }),
    customGatewaySetting: builder.query<Pto.CustomGatewaySettings.CustomGatewaySetting, string>({
      query: (customGatewaySettingId) => ({
        url: `custom-gateway-settings/${customGatewaySettingId}`
      }),
      providesTags: (_result, _error, customGatewaySettingId) => getProvidedTags('CustomGatewaySettings', customGatewaySettingId)
    }),
    customGatewaySettingsOptions: builder.query<Pto.Option[], string>({
      query: (searchText) => ({
        url: 'custom-gateway-settings/options',
        params: { searchText }
      })
    }),
    customGatewaySettingBySupplier: builder.query<Pto.CustomGatewaySettings.CustomGatewaySetting, { customGatewaySettingId: string; supplierId: string }>({
      query: ({ customGatewaySettingId, supplierId }) => ({
        url: `custom-gateway-settings/${customGatewaySettingId}/${supplierId}`
      }),
      providesTags: (_result, _error, { customGatewaySettingId }) => getProvidedTags('CustomGatewaySettings', customGatewaySettingId)
    }),
    createCustomGatewaySetting: builder.mutation<string, Pto.CustomGatewaySettings.CreateCustomGatewaySetting>({
      query: (createGatewayDto) => ({
        url: 'custom-gateway-settings',
        body: createGatewayDto,
        method: 'POST'
      }),
      invalidatesTags: getProvidedTags('CustomGatewaySettings', 'LIST')
    }),
    updateCustomGatewaySettingName: builder.mutation<boolean, Pto.CustomGatewaySettings.ChangeName & { customGatewaySettingId: string }>({
      query: ({ customGatewaySettingId, ...args }) => ({
        url: `custom-gateway-settings/${customGatewaySettingId}/name`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { customGatewaySettingId }) => getProvidedTags('CustomGatewaySettings', customGatewaySettingId)
    }),
    updateCustomGatewaySettingIsActive: builder.mutation<boolean, Pto.CustomGatewaySettings.ChangeIsActive & { customGatewaySettingId: string }>({
      query: ({ customGatewaySettingId, ...args }) => ({
        url: `custom-gateway-settings/${customGatewaySettingId}/is-active`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { customGatewaySettingId }) => getProvidedTags('CustomGatewaySettings', customGatewaySettingId)
    }),
    updateCustomGatewaySettingClientId: builder.mutation<boolean, Pto.CustomGatewaySettings.ChangeClientId & { customGatewaySettingId: string }>({
      query: ({ customGatewaySettingId, ...args }) => ({
        url: `custom-gateway-settings/${customGatewaySettingId}/client-id`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { customGatewaySettingId }) => getProvidedTags('CustomGatewaySettings', customGatewaySettingId)
    }),
    updateCustomGatewaySettingClientSecret: builder.mutation<boolean, Pto.CustomGatewaySettings.ChangeClientSecret & { customGatewaySettingId: string }>({
      query: ({ customGatewaySettingId, ...args }) => ({
        url: `custom-gateway-settings/${customGatewaySettingId}/client-secret`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { customGatewaySettingId }) => getProvidedTags('CustomGatewaySettings', customGatewaySettingId)
    }),
    updateCustomGatewaySettingRetailerApiKey: builder.mutation<boolean, Pto.CustomGatewaySettings.ChangeRetailerApiKey & { customGatewaySettingId: string }>({
      query: ({ customGatewaySettingId, ...args }) => ({
        url: `custom-gateway-settings/${customGatewaySettingId}/retailer-api-key`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { customGatewaySettingId }) => getProvidedTags('CustomGatewaySettings', customGatewaySettingId)
    }),
    updateCustomGatewaySettingRetailerCompanyRefId: builder.mutation<boolean, Pto.CustomGatewaySettings.ChangeRetailerCompanyRefId & { customGatewaySettingId: string }>({
      query: ({ customGatewaySettingId, ...args }) => ({
        url: `custom-gateway-settings/${customGatewaySettingId}/retailer-ref-id`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { customGatewaySettingId }) => getProvidedTags('CustomGatewaySettings', customGatewaySettingId)
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Custom gateway setting') {
    store.dispatch(customGatewaySettingsApi.util.invalidateTags([{ type: 'CustomGatewaySettings', id: payload.entityId }]))
    store.dispatch(customGatewayBlankProductsApi.util.invalidateTags([{ type: 'CustomGatewayBlankProducts', id: `${payload.entityId}-LIST` }]))
    store.dispatch(customGatewayBlankProductsApi.util.invalidateTags([{ type: 'CustomGatewayBlankProducts', id: `${payload.entityId}-OPTIONS` }]))
    store.dispatch(customGatewayCategoriesApi.util.invalidateTags([{ type: 'CustomGatewayCategories', id: `${payload.entityId}-LIST` }]))
    store.dispatch(customGatewayCategoriesApi.util.invalidateTags([{ type: 'CustomGatewayCategories', id: `${payload.entityId}-OPTIONS` }]))
    store.dispatch(customGatewayProductsApi.util.invalidateTags([{ type: 'CustomGatewayProducts', id: `${payload.entityId}-LIST` }]))
    store.dispatch(customGatewayProductsApi.util.invalidateTags([{ type: 'CustomGatewayProducts', id: `${payload.entityId}-OPTIONS` }]))
  }
}

export const subscribeToCustomGatewaySettingEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromCustomGatewaySettingEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useCustomGatewaySettingQuery,
  useCustomGatewaySettingListQuery,
  useCustomGatewaySettingsOptionsQuery,
  useCustomGatewaySettingBySupplierQuery,
  useCreateCustomGatewaySettingMutation,
  useUpdateCustomGatewaySettingNameMutation,
  useUpdateCustomGatewaySettingIsActiveMutation,
  useUpdateCustomGatewaySettingClientIdMutation,
  useUpdateCustomGatewaySettingClientSecretMutation,
  useUpdateCustomGatewaySettingRetailerApiKeyMutation,
  useUpdateCustomGatewaySettingRetailerCompanyRefIdMutation
} = customGatewaySettingsApi
