import type { InputRef } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Input, Popconfirm, Space, Typography } from 'antd'

import styles from './EditableTextInput.module.scss'

type Props = {
  title?: string
  sourceValue: string
  tabIndex?: number
  prefix?: string
  padding?: boolean

  onSave: (newValue: string) => Promise<boolean>
  onRemove?: () => Promise<boolean>
}

const EditableTextInput = (props: Props) => {
  const { sourceValue, tabIndex, title, prefix, padding = true, onSave, onRemove } = props

  const inputRef = useRef<InputRef>(null)

  const [value, setValue] = useState(props.sourceValue)
  const [isConfirmOpened, setIsConfirmOpened] = useState(false)
  const [isRemoveOpened, setIsRemoveOpened] = useState(false)

  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    if (value !== sourceValue) {
      setIsEdit(false)
      setIsConfirmOpened(false)
      setValue(sourceValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceValue])

  const handleEditClicked = () => {
    setIsEdit(true)
    inputRef.current?.focus({ cursor: 'end' })
    setIsConfirmOpened(false)
  }

  const handleBlur = () => {
    setIsConfirmOpened(true)
  }

  const handleCancelClicked = () => {
    setValue(sourceValue)
    setIsEdit(false)
    setIsConfirmOpened(false)
  }

  const handleRemoveClicked = (e: React.MouseEvent<HTMLElement>) => {
    setIsRemoveOpened(true)
  }

  const handleConfirmCancelClicked = () => {
    inputRef.current?.focus({ cursor: 'end' })
    setIsConfirmOpened(false)
    setIsRemoveOpened(false)
  }

  const handleAsyncConfirm = async () => {
    const isSaved = await onSave(value)
    setIsConfirmOpened(!isSaved)
    setIsEdit(!isSaved)
  }

  const handleAsyncRemove = async () => {
    if (!onRemove) return

    const isSaved = await onRemove()
    setIsRemoveOpened(!isSaved)
  }

  return (
    <div className={styles.root} data-padding={padding}>
      <Typography className={styles.label} hidden={!title}>
        {title}:
      </Typography>
      <Space.Compact>
        <Input
          prefix={prefix}
          tabIndex={tabIndex}
          onBlur={isRemoveOpened ? undefined : handleBlur}
          ref={inputRef}
          readOnly={!isEdit}
          onClick={() => setIsConfirmOpened(true)}
          autoFocus
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
        {!isEdit && <Button onClick={handleEditClicked} icon={<EditOutlined />} />}
        {isEdit && <Button onClick={handleCancelClicked}>Cancel</Button>}
        {isEdit && (
          <Popconfirm
            title="Are you sure update this field?"
            open={isConfirmOpened}
            onConfirm={handleAsyncConfirm}
            onCancel={handleConfirmCancelClicked}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Save</Button>
          </Popconfirm>
        )}
        {!isEdit && onRemove && (
          <Popconfirm
            title="Are you sure delete this field?"
            open={isRemoveOpened}
            onConfirm={handleAsyncRemove}
            onCancel={handleConfirmCancelClicked}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger onClick={handleRemoveClicked}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        )}
      </Space.Compact>
    </div>
  )
}

export default EditableTextInput
