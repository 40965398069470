'use client'

import { createContext, useContext } from 'react'
import { SupplierContextProps } from './provider'

export const SupplierContext = createContext<SupplierContextProps | undefined>(undefined)

SupplierContext.displayName = 'SupplierContext'

export const useSupplierContext = (): SupplierContextProps => {
  const context = useContext(SupplierContext)

  if (!context) {
    throw new Error('Use supplier context must be within supplier context provider')
  }

  return context
}
