import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const techniquesApi = createApi({
  reducerPath: 'techniqueApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Techniques'],
  endpoints: (builder) => ({
    techniqueList: builder.query<Pto.Techniques.List, Pto.Techniques.TechniqueListQuery>({
      query: (params) => ({
        url: 'techniques',
        params
      }),
      providesTags: (techniqueListData, _error, _args) =>
        getProvidedTags(
          'Techniques',
          'LIST',
          techniqueListData?.items.map((item) => item.id)
        )
    }),
    techniqueOptions: builder.query<Pto.Option[], Pto.Techniques.TechniqueOptionsQuery>({
      query: ({ searchText, size }) => ({
        url: 'techniques/options',
        params: { searchText, size }
      }),
      providesTags: (techniqueOptionsData, _error, _args) =>
        getProvidedTags(
          'Techniques',
          'OPTIONS',
          techniqueOptionsData?.map((item) => item.value)
        )
    }),
    technique: builder.query<Pto.Techniques.Technique, string>({
      query: (techniqueId) => ({
        url: `/techniques/${techniqueId}`
      }),
      providesTags: (_result, _error, techniqueId) => getProvidedTags('Techniques', techniqueId)
    }),
    createTechnique: builder.mutation<string, Pto.Techniques.CreateTechnique>({
      query: (createTechniqueDto) => ({
        url: 'techniques',
        body: createTechniqueDto,
        method: 'POST'
      }),
      invalidatesTags: getProvidedTags('Techniques', 'LIST')
    }),
    updateTechniqueName: builder.mutation<boolean, Pto.Techniques.ChangeName & { techniqueId: string }>({
      query: ({ techniqueId, ...args }) => ({
        url: `techniques/${techniqueId}/name`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { techniqueId }) => getProvidedTags('Techniques', techniqueId)
    }),
    updateTechniqueDescription: builder.mutation<boolean, Pto.Techniques.ChangeDescription & { techniqueId: string }>({
      query: ({ techniqueId, ...args }) => ({
        url: `techniques/${techniqueId}/description`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { techniqueId }) => getProvidedTags('Techniques', techniqueId)
    }),
    updateTechniqueMinimumOrderQuantity: builder.mutation<boolean, Pto.Techniques.ChangeMinimumOrderQuantity & { techniqueId: string }>({
      query: ({ techniqueId, ...args }) => ({
        url: `techniques/${techniqueId}/minimum-order-quantity`,
        body: args,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { techniqueId }) => getProvidedTags('Techniques', techniqueId)
    }),
    setImageUrls: builder.mutation<void, Pto.Techniques.SetImageUrls & { techniqueId: string }>({
      query: ({ techniqueId, ...body }) => ({
        url: `techniques/${techniqueId}/image-urls`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { techniqueId }) => getProvidedTags('Techniques', techniqueId)
    })
  })
})

export const {
  useTechniqueQuery,
  useTechniqueListQuery,
  useTechniqueOptionsQuery,
  useCreateTechniqueMutation,
  useUpdateTechniqueNameMutation,
  useUpdateTechniqueDescriptionMutation,
  useUpdateTechniqueMinimumOrderQuantityMutation,
  useSetImageUrlsMutation
} = techniquesApi
