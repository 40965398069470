import { EditOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { Button, InputNumber, InputRef, Popconfirm, Space, Typography } from 'antd'
import styles from './EditableNumberInput.module.scss'

type Props = {
  title?: string
  disabled?: boolean
  isLoading?: boolean
  min?: number
  max?: number
  precision?: number
  sourceValue: number
  tabIndex?: number
  onFinish: (newValue: number) => Promise<boolean>
  className?: string
  prefix?: string
  padding?: boolean
}

const EditableNumberInput = ({
  min = 1,
  max,
  precision = 2,
  title,
  prefix = '',
  disabled = false,
  isLoading = false,
  sourceValue,
  onFinish,
  className = '',
  tabIndex,
  padding = true
}: Props) => {
  const [value, setValue] = useState(sourceValue)
  const [isConfirmOpened, setIsConfirmOpened] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    if (value !== sourceValue) {
      setIsEdit(false)
      setIsConfirmOpened(false)
      setValue(sourceValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceValue])

  const handleEditClicked = () => {
    setIsEdit(true)
    inputRef.current?.focus({ cursor: 'end' })
    setIsConfirmOpened(false)
  }

  const handleBlur = () => {
    setIsConfirmOpened(true)
  }

  const handleConfirmCancelClicked = () => {
    inputRef.current?.focus({ cursor: 'end' })
    setIsConfirmOpened(false)
  }

  const handleCancelClicked = () => {
    setValue(sourceValue)
    setIsEdit(false)
    setIsConfirmOpened(false)
  }

  const handleAsyncConfirm = async () => {
    const isSaved = await onFinish(value)
    setIsConfirmOpened(!isSaved)
    setIsEdit(!isSaved)
  }

  return (
    <div className={[styles.root, className].join(' ')} data-padding={padding}>
      <Typography className={styles.label} hidden={!title}>
        {title}:
      </Typography>
      <Space.Compact>
        <InputNumber
          prefix={prefix}
          tabIndex={tabIndex}
          onBlur={handleBlur}
          // @ts-expect-error Не подходит тип с антд, но нужный тип рефа не нашел
          ref={inputRef}
          readOnly={!isEdit}
          onClick={() => setIsConfirmOpened(true)}
          autoFocus
          value={value}
          onChange={(price) => setValue(price || 0)}
          disabled={isLoading || disabled}
          min={min}
          max={max}
          precision={precision}
          style={{ width: '100%' }}
          changeOnWheel={false}
        />
        {!isEdit && <Button onClick={handleEditClicked} icon={<EditOutlined />} />}
        {isEdit && <Button onClick={handleCancelClicked}>Cancel</Button>}
        {isEdit && (
          <Popconfirm
            title="Are you sure update this field?"
            open={isConfirmOpened}
            onConfirm={handleAsyncConfirm}
            onCancel={handleConfirmCancelClicked}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Save</Button>
          </Popconfirm>
        )}
      </Space.Compact>
    </div>
  )
}

export default EditableNumberInput
