import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type SettingArgs = {
  customGatewaySettingsId: string
}

type ProductSettingArgs = SettingArgs & {
  customGatewayProductId: string
}

export const customGatewayProductsApi = createApi({
  reducerPath: 'customGatewayProductsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['CustomGatewayProducts'],
  endpoints: (builder) => ({
    customGatewayProductsList: builder.query<Pto.CustomGatewayProducts.List, Pto.CustomGatewayProducts.ListArgs & SettingArgs>({
      query: ({ customGatewaySettingsId, searchText, page, size }) => ({
        url: `/custom-gateway-products/${customGatewaySettingsId}/list`,
        params: { searchText, page, size }
      }),
      providesTags: (_result, _error, { customGatewaySettingsId }) => getProvidedTags('CustomGatewayProducts', `${customGatewaySettingsId}-LIST`)
    }),
    customGatewayProductOptions: builder.query<Pto.Option[], Pto.CustomGatewayProducts.OptionArgs & SettingArgs>({
      query: ({ customGatewaySettingsId, searchText, requiredItemId }) => ({
        url: `/custom-gateway-products/${customGatewaySettingsId}/options`,
        params: { searchText, requiredItemId }
      }),
      providesTags: (_result, _error, { customGatewaySettingsId }) => getProvidedTags('CustomGatewayProducts', `${customGatewaySettingsId}-OPTIONS`)
    }),
    customGatewayProduct: builder.query<Pto.CustomGatewayProducts.Product, SettingArgs & { customGatewayProductId: string }>({
      query: ({ customGatewaySettingsId, customGatewayProductId }) => `/custom-gateway-products/${customGatewaySettingsId}/${customGatewayProductId}`
    }),
    createCustomGatewayProduct: builder.mutation<string, Pto.CustomGatewayProducts.Create & SettingArgs>({
      query: ({ customGatewaySettingsId, ...body }) => ({
        url: `/custom-gateway-products/${customGatewaySettingsId}`,
        body,
        method: 'POST'
      })
    }),
    changeCustomGatewayProductName: builder.mutation<void, Pto.CustomGatewayProducts.ChangeName & ProductSettingArgs>({
      query: ({ customGatewaySettingsId, customGatewayProductId, name }) => ({
        url: `/custom-gateway-products/${customGatewaySettingsId}/${customGatewayProductId}/name`,
        body: { name },
        method: 'PATCH'
      })
    }),
    changeCustomGatewayProductCategory: builder.mutation<void, Pto.CustomGatewayProducts.ChangeCategory & ProductSettingArgs>({
      query: ({ customGatewaySettingsId, customGatewayProductId, categoryId }) => ({
        url: `/custom-gateway-products/${customGatewaySettingsId}/${customGatewayProductId}/category`,
        body: { categoryId },
        method: 'PATCH'
      })
    }),
    changeCustomGatewayProductBlankProduct: builder.mutation<void, Pto.CustomGatewayProducts.ChangeBlankProduct & ProductSettingArgs>({
      query: ({ customGatewaySettingsId, customGatewayProductId, blankProductId }) => ({
        url: `/custom-gateway-products/${customGatewaySettingsId}/${customGatewayProductId}/blank-product`,
        body: { blankProductId },
        method: 'PATCH'
      })
    }),
    changeCustomGatewayProductBlankProductVariant: builder.mutation<void, Pto.CustomGatewayProducts.ChangeBlankProductVariant & ProductSettingArgs>({
      query: ({ customGatewaySettingsId, customGatewayProductId, blankProductVariantId }) => ({
        url: `/custom-gateway-products/${customGatewaySettingsId}/${customGatewayProductId}/blank-product-variant`,
        body: { blankProductVariantId },
        method: 'PATCH'
      })
    }),
    removeCustomGatewayProduct: builder.mutation<void, ProductSettingArgs>({
      query: ({ customGatewaySettingsId, customGatewayProductId }) => ({
        url: `/custom-gateway-products/${customGatewaySettingsId}/${customGatewayProductId}`,
        method: 'DELETE'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Custom gateway setting') {
    store.dispatch(customGatewayProductsApi.util.invalidateTags([{ type: 'CustomGatewayProducts', id: payload.entityId }]))
  }
}

export const subscribeToCustomGatewayProductEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromCustomGatewayProductEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useCustomGatewayProductsListQuery,
  useCustomGatewayProductOptionsQuery,
  useCustomGatewayProductQuery,
  useCreateCustomGatewayProductMutation,
  useChangeCustomGatewayProductNameMutation,
  useChangeCustomGatewayProductCategoryMutation,
  useChangeCustomGatewayProductBlankProductMutation,
  useChangeCustomGatewayProductBlankProductVariantMutation,
  useRemoveCustomGatewayProductMutation
} = customGatewayProductsApi
