import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const shipstationSettingsApi = createApi({
  reducerPath: 'shipstationSettingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['ShipstationSettings'],
  endpoints: (builder) => ({
    shipstationSettingList: builder.query<Pto.ShipstationSettings.List, Pto.ShipstationSettings.ShipstationSettingListQuery>({
      query: (params) => ({
        url: 'shipstation-settings',
        params
      }),
      providesTags: (gatewayListData, _error, _args) =>
        getProvidedTags(
          'ShipstationSettings',
          'LIST',
          gatewayListData?.items.map((item) => item.id)
        )
    }),
    shipstationSetting: builder.query<Pto.ShipstationSettings.ShipstationSetting, string>({
      query: (shipstationSettingId) => ({
        url: `/shipstation-settings/${shipstationSettingId}`
      }),
      providesTags: (_result, _error, shipstationSettingId) => getProvidedTags('ShipstationSettings', shipstationSettingId)
    }),
    shipstationSettingBySupplier: builder.query<Pto.ShipstationSettings.ShipstationSetting, { shipstationSettingId: string; supplierId: string }>({
      query: ({ shipstationSettingId, supplierId }) => ({
        url: `/shipstation-settings/${shipstationSettingId}/${supplierId}`
      }),
      providesTags: (_result, _error, { shipstationSettingId }) => getProvidedTags('ShipstationSettings', shipstationSettingId)
    }),
    createShipstationSetting: builder.mutation<string, Pto.ShipstationSettings.CreateShipstationSetting>({
      query: (createGatewayDto) => ({
        url: 'shipstation-settings',
        body: createGatewayDto,
        method: 'POST'
      }),
      invalidatesTags: getProvidedTags('ShipstationSettings', 'LIST')
    }),
    updateShipstationSettingName: builder.mutation<boolean, Pto.ShipstationSettings.ChangeName & { shipstationSettingId: string }>({
      query: ({ shipstationSettingId, ...args }) => ({
        url: `shipstation-settings/${shipstationSettingId}/name`,
        body: args,
        method: 'PATCH'
      })
    }),
    updateShipstationSettingIsActive: builder.mutation<boolean, Pto.ShipstationSettings.ChangeIsActive & { shipstationSettingId: string }>({
      query: ({ shipstationSettingId, ...args }) => ({
        url: `shipstation-settings/${shipstationSettingId}/is-active`,
        body: args,
        method: 'PATCH'
      })
    }),
    updateShipstationSettingApiKey: builder.mutation<boolean, Pto.ShipstationSettings.ChangeApiKey & { shipstationSettingId: string }>({
      query: ({ shipstationSettingId, ...args }) => ({
        url: `shipstation-settings/${shipstationSettingId}/api-key`,
        body: args,
        method: 'PATCH'
      })
    }),
    updateShipstationSettingApiSecret: builder.mutation<boolean, Pto.ShipstationSettings.ChangeApiSecret & { shipstationSettingId: string }>({
      query: ({ shipstationSettingId, ...args }) => ({
        url: `shipstation-settings/${shipstationSettingId}/api-secret`,
        body: args,
        method: 'PATCH'
      })
    }),
    updateShipstationSettingStoreId: builder.mutation<boolean, Pto.ShipstationSettings.ChangeStoreId & { shipstationSettingId: string }>({
      query: ({ shipstationSettingId, ...args }) => ({
        url: `shipstation-settings/${shipstationSettingId}/store-id`,
        body: args,
        method: 'PATCH'
      })
    }),
    updateShipstationSettingWarehouseId: builder.mutation<boolean, Pto.ShipstationSettings.ChangeWarehouseId & { shipstationSettingId: string }>({
      query: ({ shipstationSettingId, ...args }) => ({
        url: `shipstation-settings/${shipstationSettingId}/warehouse-id`,
        body: args,
        method: 'PATCH'
      })
    }),
    subscribeWebhook: builder.mutation<boolean, { shipstationSettingId: string }>({
      query: ({ shipstationSettingId }) => ({
        url: `shipstation-settings/${shipstationSettingId}/subscribe`,
        method: 'PATCH'
      })
    }),
    unsubscribeWebhook: builder.mutation<boolean, { shipstationSettingId: string }>({
      query: ({ shipstationSettingId }) => ({
        url: `shipstation-settings/${shipstationSettingId}/unsubscribe`,
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Shipstation setting') {
    store.dispatch(shipstationSettingsApi.util.invalidateTags([{ type: 'ShipstationSettings', id: payload.entityId }]))
  }
}

export const subscribeToShipstationSettingEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromShipstationSettingEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useShipstationSettingQuery,
  useShipstationSettingBySupplierQuery,
  useShipstationSettingListQuery,
  useCreateShipstationSettingMutation,
  useUpdateShipstationSettingNameMutation,
  useUpdateShipstationSettingIsActiveMutation,
  useUpdateShipstationSettingApiKeyMutation,
  useUpdateShipstationSettingApiSecretMutation,
  useUpdateShipstationSettingStoreIdMutation,
  useUpdateShipstationSettingWarehouseIdMutation,
  useSubscribeWebhookMutation,
  useUnsubscribeWebhookMutation
} = shipstationSettingsApi
