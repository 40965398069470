import { toast } from 'react-toastify'
import React, { useRef, useState } from 'react'
import { Button, Checkbox, Input, InputRef } from 'antd'

import { authProvider } from 'app/auth/auth-provider'
import { useLoginMutation } from 'app/api/users-api'

import styles from './Login.module.scss'

const Login = () => {
  const passwordRef = useRef<InputRef>(null)

  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [rememberMe, setRememberMe] = useState<boolean>(false)

  const [login, { isLoading }] = useLoginMutation()

  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = ({ currentTarget }) => setEmail(currentTarget.value)
  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = ({ currentTarget }) => setPassword(currentTarget.value)
  const handleClickRememberMe = () => setRememberMe(!rememberMe)

  const handleSubmit: React.FormEventHandler<HTMLElement> = async (e) => {
    e.preventDefault()
    passwordRef.current?.focus()
    if (!isLoading && email && password) {
      await login({ email, password })
        .unwrap()
        .then((userData) => {
          authProvider.signIn(userData.token, userData.refreshToken, rememberMe)
        })
        .catch((error) => toast.error(error))
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.image}>
          <img className={styles.main} src="/main.jpeg" alt="Merchconnect products" />
          <img className={styles.logo} src="/logo.svg" alt="Merchconnect logo" />
        </div>
        <div className={styles.formBox}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <Input autoFocus={true} value={email} onChange={handleChangeEmail} placeholder="Email" />
            <Input.Password ref={passwordRef} value={password} onChange={handleChangePassword} placeholder="Password" />
            <Checkbox checked={rememberMe} onClick={handleClickRememberMe}>
              Remember me
            </Checkbox>
            <Button type="primary" htmlType="submit" size="large" className={styles.submitButton} loading={isLoading} disabled={isLoading}>
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
