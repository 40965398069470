import { TDocumentDefinitions, Content } from 'pdfmake/interfaces'
import JsBarcode from 'jsbarcode'
import { Pto } from '@merchx-v3/pto'

export const generateBarcode = (labels: Pto.SupplierOrderFulfillments.Tasks.BarcodeListItem[]): TDocumentDefinitions => {
  const content: Content[] = labels.map((item, indx) => {
    let canvas = document.createElement('canvas')
    JsBarcode(canvas, item.barcode, { format: 'codabar', font: 'Calibri', displayValue: false, width: 6, height: 62 })
    const barcode = canvas.toDataURL()

    const description = `${item.productName} ${item.productVariant}`

    const result: Content = [
      {
        fontSize: 9,
        columns: [
          { width: 'auto', text: item.printAreas, alignment: 'left' },
          { width: '*', text: `Quantity: ${item.quantities.lineQuantity}`, alignment: 'right' }
        ]
      },
      { fontSize: 12, bold: true, text: description.slice(0, 68), alignment: 'center' },
      { image: barcode, width: 274, alignment: 'center', marginTop: 2 },
      {
        fontSize: 9,
        columns: [
          { width: 'auto', text: `ID: ${item.fulfillmentTaskNumber}`, alignment: 'left' },
          { width: '*', text: `${item.barcode} - (${item.externalTaskItemId})`, alignment: 'center' },
          { width: 'auto', text: `Total items: ${item.quantities.taskTotal}`, alignment: 'right' }
        ],
        columnGap: 5
      }
    ]

    if (indx !== labels.length - 1) {
      result.push({ text: '', pageBreak: 'after' })
    }

    return result
  })

  return {
    content,
    pageSize: { width: 312, height: 85 },
    pageMargins: [20, 6, 20, 0],
    defaultStyle: {
      fontSize: 10,
      lineHeight: 0.8
    }
  }
}
