import 'reflect-metadata'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import 'reset-css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-chat-elements/dist/main.css'
import './index.scss'
import App from 'components/App'
import { ToastContainer } from 'react-toastify'

// get root container
const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer autoClose={2000} position="top-center" pauseOnHover />
    </Provider>
  </React.StrictMode>
)

// init deploy
