enum AppRoutes {
  // ADMIN ROUTES

  dashboard = '/',
  carts = 'carts',
  customers = 'customers',
  customerReport = 'customers/report',
  customGatewaySettings = 'settings/custom-gateway-settings',
  customGatewayCategories = 'settings/custom-gateway-categories',
  chats = 'chats',
  products = 'products',
  supplierProducts = 'supplier-products',
  ecomProducts = 'ecom-products',
  projects = 'projects',
  invoices = 'invoices',
  payments = 'payments',
  orders = 'orders',
  fulfillment = 'fulfillment',
  designRequest = 'design-requests',
  main = 'main',
  design = 'designs',
  suppliers = 'suppliers',
  attributes = 'settings/attributes',
  categories = 'settings/categories',
  shipping = 'shipping',
  shipstationSettings = 'settings/shipstation-settings',
  settings = 'settings',
  techniques = 'settings/techniques',
  plugins = 'settings/plugins',
  pluginFulfillmentList = 'settings/plugins/fulfillment-list',
  pluginFulfillment = 'settings/plugins/fulfillment',
  profile = 'profile',
  newPluginFulfillment = 'settings/plugins/fulfillment/new',
  pluginShippingList = 'settings/plugins/shipping-list',
  pluginShipping = 'settings/plugins/shipping',
  sagas = 'sagas',
  newPluginShipping = 'settings/plugins/shipping/new',
  users = 'settings/users',
  createUser = 'settings/users/create',
  processingTemplates = 'processing-templates',

  supplierOrders = 'supplier-orders',
  supplierOrderDesigns = 'supplier-order-designs',
  supplierOrderShippings = 'supplier-order-shippings',
  supplierOrderFulfillments = 'supplier-order-fulfillments'
}

export default AppRoutes
