import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type FulfillmentSystemOptionsQuery = {
  searchText?: string
  supplierId: string
  fulfillmentSystemType: Pto.SupplierOrderFulfillments.FulfillmentSystemType
}

export const fulfillmentSystemsApi = createApi({
  reducerPath: 'fulfillmentSystemsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['FulfillmentSystems'],
  endpoints: (builder) => ({
    fulfillmentSystemOptions: builder.query<Pto.Option[], FulfillmentSystemOptionsQuery>({
      query: (params) => ({
        url: 'fulfillment-systems/options',
        params
      }),
      providesTags: (_options, _error, _args) => getProvidedTags('FulfillmentSystems', 'OPTIONS')
    })
  })
})

export const { useFulfillmentSystemOptionsQuery } = fulfillmentSystemsApi
