import useDebounce from 'helpers/useDebounce'
import { useCallback, useEffect, useState } from 'react'

import { SendTypingParams } from '../types'
import { ConversationContext } from './context'
import { ConversationProviderProps } from './types'
import { useLazyServiceFactoryRef } from './use-lazy-service-factory'
import { useThrottledSendTyping } from './use-throttled-send-typing'

export const ConversationProvider = ({ serviceFactory, storage, config: { typingThrottleTime = 900 }, children }: ConversationProviderProps): JSX.Element => {
  const [state, setState] = useState(storage.getState())
  const [currentMessage, setCurrentMessage] = useState<string>('')

  const debouncedCurrentMessage = useDebounce(currentMessage, 250)

  const updateState = useCallback(() => {
    const newState = storage.getState()
    setState(newState)
  }, [setState, storage])

  const serviceRef = useLazyServiceFactoryRef(serviceFactory, storage, updateState)
  const service = serviceRef.current

  const [isOnline, setIsOnline] = useState<boolean>(service.isWebSocketConnected)
  const [isConversationsListLoaded, setIsConversationsListLoaded] = useState<boolean>(service.isConversationsConnected)

  const onChatConnected = useCallback(() => {
    setIsOnline(true)
  }, [])

  const onChatDisconnected = useCallback(() => {
    setIsOnline(false)
  }, [])

  const onConversationListLoaded = useCallback(() => {
    setIsConversationsListLoaded(true)
  }, [])

  useEffect(() => {
    service.addEventListener('connected', onChatConnected)
    service.addEventListener('disconnected', onChatDisconnected)
    service.addEventListener('conversation-list-loaded', onConversationListLoaded)

    return () => {
      service.removeEventListener('connected', onChatConnected)
      service.removeEventListener('disconnected', onChatDisconnected)
      service.removeEventListener('conversation-list-loaded', onConversationListLoaded)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service])

  useEffect(() => {
    storage.setCurrentMessage(debouncedCurrentMessage)
    updateState()
  }, [storage, updateState, debouncedCurrentMessage])

  const throttledSendTyping = useThrottledSendTyping(serviceRef.current, typingThrottleTime)

  const sendTyping = useCallback(
    ({ content = '', isTyping = true, throttle = true }: SendTypingParams) => {
      const { activeConversation, currentUser } = storage.getState()
      if (activeConversation && currentUser) {
        const params = {
          content,
          isTyping,
          userId: currentUser.id,
          conversationId: activeConversation.id
        }

        if (throttle) {
          throttledSendTyping(params)
        } else {
          serviceRef.current.sendTyping(params)
        }
      }
    },
    [storage, throttledSendTyping, serviceRef]
  )

  const setVisible = useCallback(
    (isVisible: boolean) => {
      storage.setVisible(isVisible)
      updateState()
    },
    [storage, updateState]
  )

  return (
    <ConversationContext.Provider
      value={{
        ...state,
        isOnline,
        isConversationsListLoaded,
        // addConversation,
        getUser: serviceRef.current.getUser,
        setActiveConversation: serviceRef.current.setActiveConversation,
        sendTyping,
        setVisible,
        currentMessage,
        setCurrentMessage
        // addUser
      }}
    >
      {children}
    </ConversationContext.Provider>
  )
}
