enum AppRoutes {
  // COMMON ROUTES

  dashboard = '/',

  customers = 'customers',
  customerReport = 'customers/report',

  legacySupplierProducts = 'legacy/supplier-products',
  legacyEcomProducts = 'legacy/ecom-products',
  legacyProjects = 'legacy/projects',
  legacyFulfillment = 'legacy/fulfillment',
  legacyDesignRequest = 'legacy/design-requests',
  legacyDesign = 'legacy/designs',
  legacyShipping = 'legacy/shipping',
  settings = 'settings',
  invoices = 'invoices',

  customGateway = 'custom-gateway',
  shipstation = 'shipstation',
  designTemplates = 'design-templates',
  profile = 'profile',
  processingTemplates = 'processing-templates',
  carts = 'carts',
  supplierOrders = 'supplier-orders',
  supplierOrderDesigns = 'supplier-order-designs',
  supplierOrderShippings = 'supplier-order-shippings',
  supplierOrderFulfillments = 'supplier-order-fulfillments',
  products = 'products',
  supplierOrderShippingTasks = 'supplier-order-shipping-tasks',
  supplierOrderShippingTaskDetails = 'supplier-order-shipping-tasks/:shippingTaskId',
  supplierOrderFulfillmentTasks = 'supplier-order-fulfillment-tasks',
  supplierOrderFulfillmentTaskDetails = 'supplier-order-fulfillment-tasks/:fulfillmentTaskId'
}

export default AppRoutes
