import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type FulfillmentArgs = {
  fulfillmentId: string
}

type FulfillmentTaskArgs = {
  fulfillmentId: string
  fulfillmentTaskId: string
}

type FulfillmentTaskItemArgs = {
  fulfillmentId: string
  fulfillmentTaskId: string
  fulfillmentTaskItemId: string
}

export const supplierOrderFulfillmentsApi = createApi({
  reducerPath: 'supplierOrderFulfilmentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['SupplierOrderFulfillments', 'SupplierOrderFulfillmentTasks'],

  // /supplier-order-fulfillment/:supplierOrderFulfillmentId/send-to-fulfillment

  endpoints: (builder) => ({
    supplierOrderFulfillment: builder.query<Pto.SupplierOrderFulfillments.SupplierOrderFulfillment, string>({
      query: (fulfillmentId) => `/supplier-order-fulfillments/${fulfillmentId}`,
      providesTags: (_result, _error, fulfillmentId) => getProvidedTags('SupplierOrderFulfillments', fulfillmentId)
    }),
    supplierOrderFulfillmentList: builder.query<Pto.SupplierOrderFulfillments.List, Pto.SupplierOrderFulfillments.ListArgs>({
      query: (params) => ({
        url: 'supplier-order-fulfillments',
        params: params
      }),
      providesTags: (supplierOrderFulfillmentListData, _error, _args) =>
        getProvidedTags(
          'SupplierOrderFulfillments',
          'LIST',
          supplierOrderFulfillmentListData?.items.map((item) => item.id)
        )
    }),
    supplierOrderFulfillmentListBadges: builder.query<Pto.SupplierOrderFulfillments.ListBadges, Pto.SupplierOrderFulfillments.ListBadgeArgs>({
      query: (params) => ({
        url: 'supplier-order-fulfillments/list-badges',
        params
      })
    }),

    getTaskStatusCounts: builder.query<Pto.SupplierOrderFulfillments.Tasks.TaskStatusCount, void>({
      query: () => ({
        url: `/supplier-order-fulfillment-tasks/status-counts`,
        method: 'GET'
      }),
      providesTags: ['SupplierOrderFulfillments']
    }),

    supplierOrderFulfillmentTaskList: builder.query<Pto.SupplierOrderFulfillments.Tasks.List, Pto.SupplierOrderFulfillments.Tasks.ListArgs>({
      query: (listArgs) => ({
        url: `supplier-order-fulfillment-tasks`,
        params: listArgs,
        method: 'GET'
      }),
      providesTags: (fulfillmentTaskListData, _error, _args) =>
        getProvidedTags(
          'SupplierOrderFulfillments',
          'LIST',
          fulfillmentTaskListData?.items.map((item) => item.supplierOrderId)
        )
    }),

    supplierOrderFulfillmentTask: builder.query<Pto.SupplierOrderFulfillments.Tasks.Details, string>({
      query: (fulfillmentTaskId) => `/supplier-order-fulfillment-tasks/${fulfillmentTaskId}`,
      providesTags: (result, _error, fulfillmentTaskId) => (result ? getProvidedTags('SupplierOrderFulfillments', result.supplierOrderId) : [])
    }),

    supplierOrderFulfillmentTaskBarcodes: builder.query<Pto.SupplierOrderFulfillments.Tasks.BarcodeList, Pto.SupplierOrderFulfillments.Tasks.BarcodeListArgs>({
      query: ({ taskIds }) => ({
        url: `/supplier-order-fulfillment-tasks/barcodes/list`,
        body: taskIds,
        method: 'POST'
      })
    }),

    sendToFulfillment: builder.mutation<void, string>({
      query: (fulfillmentId) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/send-to-fulfillment`,
        method: 'POST'
      })
    }),

    startTaskProcessing: builder.mutation<void, FulfillmentTaskArgs>({
      query: ({ fulfillmentId, fulfillmentTaskId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/fulfillment-tasks/${fulfillmentTaskId}/start-processing`,
        method: 'PATCH'
      })
    }),

    completeTask: builder.mutation<void, FulfillmentTaskArgs>({
      query: ({ fulfillmentId, fulfillmentTaskId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/fulfillment-tasks/${fulfillmentTaskId}/complete`,
        method: 'PATCH'
      })
    }),

    cancelTask: builder.mutation<void, FulfillmentTaskArgs & Pto.SupplierOrderFulfillments.Commands.Args.CancelTask>({
      query: ({ fulfillmentId, fulfillmentTaskId, reason }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/fulfillment-tasks/${fulfillmentTaskId}/cancel`,
        method: 'PATCH',
        body: { reason }
      })
    }),

    holdTask: builder.mutation<void, FulfillmentTaskArgs & Pto.SupplierOrderFulfillments.Commands.Args.HoldTask>({
      query: ({ fulfillmentId, fulfillmentTaskId, reason }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/fulfillment-tasks/${fulfillmentTaskId}/hold`,
        method: 'PATCH',
        body: { reason }
      })
    }),

    completeTaskItem: builder.mutation<void, FulfillmentTaskItemArgs>({
      query: ({ fulfillmentId, fulfillmentTaskId, fulfillmentTaskItemId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/fulfillment-tasks/${fulfillmentTaskId}/complete-item/${fulfillmentTaskItemId}`,
        method: 'PATCH'
      })
    }),

    holdTaskItem: builder.mutation<void, FulfillmentTaskItemArgs & Pto.SupplierOrderFulfillments.Commands.Args.HoldTaskItem>({
      query: ({ fulfillmentId, fulfillmentTaskId, fulfillmentTaskItemId, reason }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/fulfillment-tasks/${fulfillmentTaskId}/hold-item/${fulfillmentTaskItemId}`,
        method: 'PATCH',
        body: { reason }
      })
    }),

    cancelTaskItem: builder.mutation<void, FulfillmentTaskItemArgs & Pto.SupplierOrderFulfillments.Commands.Args.CancelTaskItem>({
      query: ({ fulfillmentId, fulfillmentTaskId, fulfillmentTaskItemId, reason }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/fulfillment-tasks/${fulfillmentTaskId}/cancel-item/${fulfillmentTaskItemId}`,
        method: 'PATCH',
        body: { reason }
      })
    }),

    completeFulfillment: builder.mutation<void, FulfillmentArgs>({
      query: ({ fulfillmentId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/complete`,
        method: 'PATCH'
      })
    }),

    holdFulfillment: builder.mutation<void, FulfillmentArgs & Pto.SupplierOrderFulfillments.Hold>({
      query: ({ fulfillmentId, reason }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/hold`,
        method: 'PATCH',
        body: { reason }
      })
    }),
    takeResponsibility: builder.mutation<string, { fulfillmentId: string }>({
      query: ({ fulfillmentId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/responsible-users`,
        method: 'PUT'
      })
    }),
    dropResponsibility: builder.mutation<string, { fulfillmentId: string }>({
      query: ({ fulfillmentId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/responsible-users`,
        method: 'DELETE'
      })
    }),
    addResponsible: builder.mutation<string, { fulfillmentId: string; responsibleUserId: string }>({
      query: ({ fulfillmentId, responsibleUserId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/responsible-users/${responsibleUserId}`,
        method: 'PUT'
      })
    }),
    removeResponsible: builder.mutation<string, { fulfillmentId: string; responsibleUserId: string }>({
      query: ({ fulfillmentId, responsibleUserId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/responsible-users/${responsibleUserId}`,
        method: 'DELETE'
      })
    }),
    updateSupplierOrderFulfillmentComment: builder.mutation<string, { fulfillmentId: string; comment: string }>({
      query: ({ fulfillmentId, comment }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/comment`,
        body: { comment },
        method: 'PATCH'
      })
    }),
    takeResponsibilityForTask: builder.mutation<string, { fulfillmentId: string, fulfillmentTaskId: string }>({
      query: ({ fulfillmentId, fulfillmentTaskId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/${fulfillmentTaskId}/responsible-users`,
        method: 'PUT'
      })
    }),
    dropResponsibilityForTask: builder.mutation<string, { fulfillmentId: string, fulfillmentTaskId: string }>({
      query: ({ fulfillmentId, fulfillmentTaskId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/${fulfillmentTaskId}/responsible-users`,
        method: 'DELETE'
      })
    }),
    addResponsibleForTask: builder.mutation<string, { fulfillmentId: string; responsibleUserId: string; fulfillmentTaskId: string }>({
      query: ({ fulfillmentId, fulfillmentTaskId, responsibleUserId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/${fulfillmentTaskId}/responsible-users/${responsibleUserId}`,
        method: 'PUT'
      })
    }),
    removeResponsibleForTask: builder.mutation<string, { fulfillmentId: string; responsibleUserId: string; fulfillmentTaskId: string }>({
      query: ({ fulfillmentId, fulfillmentTaskId, responsibleUserId }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/${fulfillmentTaskId}/responsible-users/${responsibleUserId}`,
        method: 'DELETE'
      })
    }),
    updateSupplierOrderFulfillmentTaskComment: builder.mutation<string, { fulfillmentId: string; comment: string; fulfillmentTaskId: string }>({
      query: ({ fulfillmentId, fulfillmentTaskId, comment }) => ({
        url: `/supplier-order-fulfillments/${fulfillmentId}/${fulfillmentTaskId}/comment`,
        body: { comment },
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Supplier order fulfillment') {
    store.dispatch(supplierOrderFulfillmentsApi.util.invalidateTags([{ type: 'SupplierOrderFulfillments', id: payload.entityId }]))
  }
}

export const subscribeToSupplierOrderFulfillmentsEvents = (socket: WebSocket.MxWebSocket, _dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSupplierOrderFulfillmentsEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useSupplierOrderFulfillmentListQuery,
  useSupplierOrderFulfillmentListBadgesQuery,
  useGetTaskStatusCountsQuery,
  useSupplierOrderFulfillmentQuery,
  useSupplierOrderFulfillmentTaskListQuery,
  useSupplierOrderFulfillmentTaskQuery,
  useSupplierOrderFulfillmentTaskBarcodesQuery,
  useCompleteTaskMutation,
  useCancelTaskMutation,
  useSendToFulfillmentMutation,
  useStartTaskProcessingMutation,
  useHoldTaskMutation,
  useCompleteTaskItemMutation,
  useHoldTaskItemMutation,
  useCancelTaskItemMutation,
  useCompleteFulfillmentMutation,
  useHoldFulfillmentMutation,
  useTakeResponsibilityMutation,
  useDropResponsibilityMutation,
  useAddResponsibleMutation,
  useRemoveResponsibleMutation,
  useUpdateSupplierOrderFulfillmentCommentMutation,
  useTakeResponsibilityForTaskMutation,
  useDropResponsibilityForTaskMutation,
  useAddResponsibleForTaskMutation,
  useRemoveResponsibleForTaskMutation,
  useUpdateSupplierOrderFulfillmentTaskCommentMutation
} = supplierOrderFulfillmentsApi
