import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const supplierOrdersApi = createApi({
  reducerPath: 'supplierOrdersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['SupplierOrders'],

  endpoints: (builder) => ({
    supplierOrder: builder.query<Pto.SupplierOrders.SupplierOrder, string>({
      query: (supplierOrderId) => `/supplier-orders/${supplierOrderId}`,
      providesTags: (_result, _error, supplierOrderId) => getProvidedTags('SupplierOrders', supplierOrderId)
    }),
    supplierOrderList: builder.query<Pto.SupplierOrders.List, Pto.SupplierOrders.ListArgs>({
      query: (params) => ({
        url: 'supplier-orders',
        params
      }),
      providesTags: (supplierOrderListData, _error, _args) =>
        getProvidedTags(
          'SupplierOrders',
          'LIST',
          supplierOrderListData?.items.map((item) => item.id)
        )
    }),
    supplierOrderListBadges: builder.query<Pto.SupplierOrders.ListBadges, Pto.SupplierOrders.ListBadgeArgs>({
      query: (params) => ({
        url: 'supplier-orders/list-badges',
        params
      })
    }),
    supplierOrdersByCustomerId: builder.query<Pto.SupplierOrders.List, { customerId: string; params: Pto.SupplierOrders.ListArgs }>({
      query: ({ customerId, params }) => ({
        url: `supplier-orders/customer/${customerId}`,
        params
      }),
      providesTags: (supplierOrderListData, _error, { customerId }) =>
        getProvidedTags(
          'SupplierOrders',
          customerId,
          supplierOrderListData?.items.map((item) => item.id)
        )
    }),
    confirmSupplierOrder: builder.mutation<void, { supplierOrderId: string }>({
      query: ({ supplierOrderId }) => ({
        url: `/supplier-orders/${supplierOrderId}/confirm`,
        method: 'PATCH'
      })
    }),
    completeSupplierOrder: builder.mutation<void, { supplierOrderId: string }>({
      query: ({ supplierOrderId }) => ({
        url: `/supplier-orders/${supplierOrderId}/complete`,
        method: 'PATCH'
      })
    }),
    cancelSupplierOrder: builder.mutation<void, { supplierOrderId: string } & Pto.SupplierOrders.Commands.DeclineOrder>({
      query: ({ supplierOrderId, reason }) => ({
        url: `/supplier-orders/${supplierOrderId}/cancel`,
        body: { reason },
        method: 'DELETE'
      })
    }),
    declineSupplierOrder: builder.mutation<void, { supplierOrderId: string } & Pto.SupplierOrders.Commands.DeclineOrder>({
      query: ({ supplierOrderId, reason }) => ({
        url: `/supplier-orders/${supplierOrderId}/decline`,
        body: { reason },
        method: 'DELETE'
      })
    }),
    startDesignSupplierOrder: builder.mutation<void, { supplierOrderId: string }>({
      query: ({ supplierOrderId }) => ({
        url: `/supplier-orders/${supplierOrderId}/start-design`,
        method: 'PATCH'
      })
    }),
    startFulfillmentSupplierOrder: builder.mutation<void, { supplierOrderId: string }>({
      query: ({ supplierOrderId }) => ({
        url: `/supplier-orders/${supplierOrderId}/start-fulfillment`,
        method: 'PATCH'
      })
    }),
    startShippingSupplierOrder: builder.mutation<void, { supplierOrderId: string }>({
      query: ({ supplierOrderId }) => ({
        url: `/supplier-orders/${supplierOrderId}/start-shipping`,
        method: 'PATCH'
      })
    }),
    registerIssue: builder.mutation<void, { supplierOrderId: string } & Pto.SupplierOrders.Commands.RegisterIssue>({
      query: ({ supplierOrderId, source, reason }) => ({
        url: `/supplier-orders/${supplierOrderId}/register-issue`,
        body: { source, reason },
        method: 'PUT'
      })
    }),
    takeResponsibility: builder.mutation<string, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `/supplier-orders/${orderId}/responsible-users`,
        method: 'PUT'
      })
    }),
    dropResponsibility: builder.mutation<string, { orderId: string }>({
      query: ({ orderId }) => ({
        url: `/supplier-orders/${orderId}/responsible-users`,
        method: 'DELETE'
      })
    }),
    addResponsible: builder.mutation<string, { orderId: string; responsibleUserId: string }>({
      query: ({ orderId, responsibleUserId }) => ({
        url: `/supplier-orders/${orderId}/responsible-users/${responsibleUserId}`,
        method: 'PUT'
      })
    }),
    removeResponsible: builder.mutation<string, { orderId: string; responsibleUserId: string }>({
      query: ({ orderId, responsibleUserId }) => ({
        url: `/supplier-orders/${orderId}/responsible-users/${responsibleUserId}`,
        method: 'DELETE'
      })
    }),
    updateSupplierOrderComment: builder.mutation<string, { orderId: string; comment: string }>({
      query: ({ orderId, comment }) => ({
        url: `/supplier-orders/${orderId}/comment`,
        body: { comment },
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Supplier order') {
    store.dispatch(supplierOrdersApi.util.invalidateTags([{ type: 'SupplierOrders', id: payload.entityId }]))
  }
}

export const subscribeToSupplierOrderEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSupplierOrdersEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useSupplierOrderListQuery,
  useSupplierOrderQuery,
  useSupplierOrderListBadgesQuery,
  useSupplierOrdersByCustomerIdQuery,
  useConfirmSupplierOrderMutation,
  useCompleteSupplierOrderMutation,
  useCancelSupplierOrderMutation,
  useDeclineSupplierOrderMutation,
  useStartDesignSupplierOrderMutation,
  useStartFulfillmentSupplierOrderMutation,
  useStartShippingSupplierOrderMutation,
  useRegisterIssueMutation,
  useTakeResponsibilityMutation,
  useDropResponsibilityMutation,
  useAddResponsibleMutation,
  useRemoveResponsibleMutation,
  useUpdateSupplierOrderCommentMutation
} = supplierOrdersApi
