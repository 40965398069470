import { Pto } from '@merchx-v3/pto'
import { WebSocket } from '@merchx-v3/web-socket'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const saleSourcesApi = createApi({
  reducerPath: 'saleSourcesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['SaleSources', 'RushOptions'],
  endpoints: (builder) => ({
    saleSourceList: builder.query<Pto.SaleSources.List, Pto.SaleSources.GetListArgs>({
      query: (params) => ({
        url: '/sale-sources',
        params
      }),
      providesTags: (saleSourceListData, _error, { supplierId }) =>
        getProvidedTags(
          'SaleSources',
          `LIST_${supplierId}`,
          saleSourceListData?.items.map((item) => item.id)
        )
    }),
    saleSourceOptions: builder.query<Pto.Option[], Pto.SaleSources.GetOptionsArgs>({
      query: (params) => ({
        url: '/sale-sources/options',
        params
      }),
      providesTags: (saleSourceOptionsData, _error, _args) =>
        getProvidedTags(
          'SaleSources',
          'OPTIONS',
          saleSourceOptionsData?.map((item) => item.value)
        )
    }),
    createSaleSource: builder.mutation<void, Pto.SaleSources.CreateSaleSource>({
      query: (createTemplateDto) => ({
        url: '/sale-sources',
        body: createTemplateDto,
        method: 'POST'
      })
    }),
    updateSaleSourceName: builder.mutation<string, Pto.SaleSources.UpdateName & { saleSourceId: string }>({
      query: ({ saleSourceId, ...body }) => ({
        url: `/sale-sources/${saleSourceId}/name`,
        body,
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Sale source') {
    store.dispatch(saleSourcesApi.util.invalidateTags([{ type: 'SaleSources', id: payload.entityId }]))
  }

  if (payload.channel === 'sale-sources' && payload.entityType === 'Supplier') {
    store.dispatch(saleSourcesApi.util.invalidateTags([{ type: 'SaleSources', id: `LIST_${payload.entityId}` }]))
  }
}

export const subscribeToSaleSourceEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSaleSourceEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const { useSaleSourceOptionsQuery, useSaleSourceListQuery, useCreateSaleSourceMutation, useUpdateSaleSourceNameMutation } = saleSourcesApi
