import { Pto } from '@merchx-v3/pto'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { Button } from 'antd'
import { generateBarcode } from './generateBarcode'

pdfMake.vfs = pdfFonts.pdfMake.vfs

type Props = {
  filename: string
  labels: Pto.SupplierOrderFulfillments.Tasks.BarcodeListItem[]
}

const PrintBarcodes = ({ labels, filename }: Props) => {
  const downloadPDF = () => {
    const docDefinition = generateBarcode(labels)
    pdfMake.createPdf(docDefinition).download(`${filename}.pdf`)
  }

  return <div>{labels.length > 0 && <Button onClick={downloadPDF}>Download PDF</Button>}</div>
}

export default PrintBarcodes
