import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type ShippingSystemOptionsQuery = {
  searchText?: string
  supplierId: string
  supplierCenterId: string
  shippingSystemType: Pto.SupplierOrderShippings.ShippingSystemType
}

export const shippingSystemsApi = createApi({
  reducerPath: 'shippingSystemsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['ShippingSystems'],
  endpoints: (builder) => ({
    shippingSystemOptions: builder.query<Pto.Option[], ShippingSystemOptionsQuery>({
      query: (params) => ({
        url: 'shipping-systems/options',
        params
      }),
      providesTags: (_options, _error, _args) => getProvidedTags('ShippingSystems', 'OPTIONS')
    })
  })
})

export const { useShippingSystemOptionsQuery } = shippingSystemsApi
