import type { InputRef } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { Button, Input, Popconfirm, Space, Typography } from 'antd'

import styles from './EditableTextArea.module.scss'

type Props = {
  title: string
  sourceValue: string
  tabIndex?: number
  onSave: (newValue: string) => Promise<boolean>
}

const EditableTextArea = (props: Props) => {
  const { sourceValue, tabIndex, title, onSave } = props

  const inputRef = useRef<InputRef>(null)

  const [value, setValue] = useState(props.sourceValue)
  const [isConfirmOpened, setIsConfirmOpened] = useState(false)

  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    if (value !== sourceValue) {
      setIsEdit(false)
      setIsConfirmOpened(false)
      setValue(sourceValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceValue])

  const handleEditClicked = () => {
    setIsEdit(true)
    inputRef.current?.focus({ cursor: 'end' })
    setIsConfirmOpened(false)
  }

  const handleBlur = () => {
    setIsConfirmOpened(true)
  }

  const handleCancelClicked = () => {
    setValue(sourceValue)
    setIsEdit(false)
    setIsConfirmOpened(false)
  }

  const handleConfirmCancelClicked = () => {
    inputRef.current?.focus({ cursor: 'end' })
    setIsConfirmOpened(false)
  }

  const handleAsyncConfirm = async () => {
    const isSaved = await onSave(value)
    setIsConfirmOpened(!isSaved)
    setIsEdit(!isSaved)
  }

  return (
    <div className={styles.root}>
      <Typography.Title level={5}>{title}:</Typography.Title>
      <Space.Compact>
        <Input.TextArea
          tabIndex={tabIndex}
          onBlur={handleBlur}
          ref={inputRef}
          readOnly={!isEdit}
          onClick={() => setIsConfirmOpened(true)}
          autoFocus
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
        {!isEdit && <Button onClick={handleEditClicked} icon={<EditOutlined />} />}
        {isEdit && <Button onClick={handleCancelClicked}>Cancel</Button>}
        {isEdit && (
          <Popconfirm
            title="Are you sure update this field?"
            open={isConfirmOpened}
            onConfirm={handleAsyncConfirm}
            onCancel={handleConfirmCancelClicked}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Save</Button>
          </Popconfirm>
        )}
      </Space.Compact>
    </div>
  )
}

export default EditableTextArea
