import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { Pto } from '@merchx-v3/pto'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import useDebounce from 'helpers/useDebounce'
import { useShippingSystemOptionsQuery } from 'app/api/shipping-systems-api'

type Props = {
  placeholder?: string
  disabled?: boolean
  className?: string
  value?: string
  size?: SizeType

  supplierId: string
  supplierCenterId: string
  shippingSystemType?: Pto.SupplierOrderShippings.ShippingSystemType
  onChange?: (value?: string) => void
}

const ShippingSystemSettingsSelect = (props: Props) => {
  const {
    placeholder,
    disabled,
    className,
    value: selectedSetting,
    size,
    supplierId,
    supplierCenterId,
    shippingSystemType = Pto.SupplierOrderShippings.ShippingSystemType.Manual,
    onChange
  } = props

  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 500)

  const { data: options = [] } = useShippingSystemOptionsQuery(
    { searchText: debouncedSearchText, supplierId, supplierCenterId, shippingSystemType },
    { skip: !supplierCenterId || !supplierId }
  )

  const handleSelect = (_value: string, option: Pto.Option) => {
    onChange && onChange(option.value)
  }

  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  const handleClear = () => {
    onChange && onChange(undefined)
    setSearchText('')
  }

  return (
    <Select
      options={options}
      className={className}
      value={selectedSetting}
      searchValue={searchText}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      allowClear
      optionFilterProp="label"
      showSearch
      onClear={handleClear}
    />
  )
}

export default ShippingSystemSettingsSelect
